import mixpanel from './mixpanel';

const isProduction = process.env.REACT_APP_MIXPANEL_ENV === 'production';

/**
 * Tracks a page view event using Mixpanel if in production environment.
 *
 * @param {Object} eventData - The data related to the page view event.
 * @returns {null} Always returns null.
 */
const trackPageView = (eventData) => {
  if (isProduction) {
    mixpanel.track('Page View', eventData);
  }
  return null;
};

export { trackPageView };
