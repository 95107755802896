import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { Datagrid, Error, Loading, useQuery } from 'react-admin';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import LastProgressDateChip from 'resources/common/LastProgressDateChip';
import { FullNameField } from 'resources/students/fields';
import { OfflineList } from 'resources/common';
import { getLastCourseUpdate } from 'utils';

const LastProgress = ({ course, record = {} }) => {
  return (
    <Typography variant="body2" align="left">
      {record[course].last_progress.name}
    </Typography>
  );
};

const ByLastProgressSubtab = (props) => {
  const { record, course } = props;
  const [students, setStudents] = useState([]);

  const {
    data: apiStudents,
    loading: studentsLoading,
    error: studentsError,
  } = useQuery({
    type: 'getManyReference',
    resource: 'students',
    payload: {
      target: 'cohort',
      id: record.id,
      pagination: {
        perPage: 100,
      },
      filter: { course: true },
      sort: { field: 'first_name', order: 'ASC' },
    },
  });

  useEffect(() => {
    if (apiStudents) {
      const tableData = R.pipe(
        R.map((student) => {
          const lastProgressDate = getLastCourseUpdate(course, student);
          return R.mergeDeepRight(student, {
            [course]: {
              last_progress: lastProgressDate,
            },
          });
        })
      )(apiStudents);
      setStudents(tableData);
    }
  }, [apiStudents, course]);

  if (studentsLoading)
    return (
      <Box p="16px">
        <Loading />
      </Box>
    );
  if (studentsError)
    return (
      <Box p="16px">
        <Error error={studentsError} errorInfo={studentsError} />
      </Box>
    );

  if (R.isEmpty(students)) {
    return null;
  }

  return (
    <OfflineList
      list={students}
      uniqueKey="id"
      defaultSort={{ field: 'first_name', order: 'ASC' }}
      sorters={{
        last_progress: R.pipe(R.pathOr([], [course, 'last_progress', 'date'])),
      }}
    >
      <Datagrid
        rowClick={(id) => {
          window.open(`/#/students/${id}/show/${course}_progress`, '_blank');
        }}
      >
        <FullNameField />
        <LastProgressDateChip
          label="Last progress date"
          source="last_progress"
          course={course}
        />
        <LastProgress
          record={record}
          course={course}
          label="Last progress"
        />
      </Datagrid>
    </OfflineList>
  );
};

export default ByLastProgressSubtab;
