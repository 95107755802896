import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import * as R from 'ramda';
import { useQuery } from 'react-admin';
import { authServer } from 'auth/keycloak';
import { trackPageView } from 'analytics/events';

const getCohortName = (cohort) => {
  const cohortLanguage = cohort?.language?.toUpperCase() || '';
  let cohortName = `${cohort.campus.toUpperCase()} ${cohort.track.toUpperCase()} ${cohort.format.toUpperCase()} ${
    cohort?.start_date?.split('T')[0] || ''
  } ${cohortLanguage}`;
  if (cohort?.campus === 'rmt') {
    cohortName += ` ${cohort.timezone} ${cohortLanguage}`;
  }
  return cohortName;
};
/**
 * Custom hook for tracking page views based on the current location.
 *
 * This hook tracks page views for specific paths related to cohorts, cohorts_b2b, and students.
 * It avoids tracking the same path multiple times and gathers additional information about the cohort or student if applicable.
 *
 * @returns {void}
 */
/**
 * Custom hook to track page views based on the current location.
 *
 * This hook uses the `useLocation` hook to get the current path and tracks page views
 * if the path is valid and has cohort information or is a base path only.
 *
 * @returns {void}
 *
 * @example
 * // Usage in a component
 * const MyComponent = () => {
 *   usePageTracking();
 *   return <div>My Component</div>;
 * };
 *
 * @hook
 *
 * @requires useLocation
 * @requires useRef
 * @requires useEffect
 * @requires useQuery
 * @requires R (Ramda library)
 * @requires trackPageView
 * @requires authServer
 * @requires getCohortName
 */
export const usePageTracking = () => {
  const location = useLocation();
  const lastTrackedPath = useRef(null);

  // Check if the current path is a valid path to track
  const isValidPath =
    R.test(
      /^\/(cohorts|cohorts_b2b|students)(\/[^/]+.*)?/,
      location?.pathname
    ) && location.pathname !== lastTrackedPath.current; // Avoid tracking the same path multiple times

  const pathInfo = R.pipe(
    R.match(
      /^\/(cohorts_b2b|cohorts|students)(\/([^/]+)(\/show\/([^/]+)(\/([^/]+))?)?)?/
    ),
    ([, resource, , id, , tab, , subtab]) => {
      return {
        resource,
        id: id || undefined,
        tab: tab || '',
        subtab: subtab || '',
      };
    }
  )(location.pathname);

  const isStudentResource = pathInfo?.resource === 'students';
  const { data: student } = useQuery(
    {
      type: 'getOne',
      resource: 'students',
      payload: { id: pathInfo?.id },
    },
    { enabled: pathInfo?.id && isStudentResource }
  );

  const isB2b = pathInfo?.resource === 'cohorts_b2b' || student?.is_b2b;
  const { data: cohort, isLoading: isLoadingCohort } = useQuery(
    {
      type: 'getOne',
      resource: isB2b ? 'cohorts_b2b' : 'cohorts',
      payload: { id: student?.cohort || pathInfo?.id },
    },
    {
      enabled:
        (pathInfo?.id && !isStudentResource) ||
        (student?.cohort && isStudentResource),
    }
  );

  const hasCohortInformation = isValidPath && !isLoadingCohort && cohort;
  const isBasePathOnly = isValidPath && !pathInfo?.id; // Base path only, e.g. /cohorts

  useEffect(() => {
    // Track page views only when path is valid and has cohort information or is a base path only
    if (hasCohortInformation || isBasePathOnly) {
      lastTrackedPath.current = location.pathname; // Update last tracked path
      trackPageView({
        page_path: location.pathname,
        cohort_type: R.has('company', cohort) ? 'B2B' : 'B2C',
        roles: R.cond([
          [R.includes('admin'), R.always(['admin'])],
          [R.T, R.identity],
        ])(authServer.getRoles()),
        user: authServer.getUser(),
        cohort_name: isBasePathOnly ? '' : getCohortName(cohort),
        page: pathInfo?.resource,
        tab: pathInfo?.tab,
        subtab: pathInfo?.subtab,
        student_id: isStudentResource ? pathInfo?.id : '',
        cohort_id: R.cond([
          [R.always(isBasePathOnly), R.always('')],
          [R.always(!isStudentResource), R.always(pathInfo?.id)],
          [R.T, R.always(cohort?.id)],
        ])(),
      });
    }
  }, [
    cohort,
    hasCohortInformation,
    isB2b,
    isBasePathOnly,
    isStudentResource,
    location,
    pathInfo,
    student,
  ]);
};
