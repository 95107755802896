import * as R from 'ramda';
import moment from 'moment-timezone';

/**
 * Determines the type of survey based on the survey and cohort information.
 *
 * @param {Object} survey - The survey object.
 * @param {number|string} survey.survey - The survey number.
 * @param {number} survey.cohort_week - The week of the cohort.
 * @param {boolean} survey.is_final_bg_survey - Indicates if it is the final background survey.
 * @param {Object} cohort - The cohort object.
 * @param {string} cohort.start_date - The start date of the cohort in UTC format.
 * @param {string} cohort.end_date - The end date of the cohort in UTC format.
 * @param {string} cohort.format - The format of the cohort, either 'pt' or other.
 * @returns {string} The type of survey, which can be '| Mid', '| Final', or an empty string.
 */
const getSurveyType = (survey, cohort) => {
  const { start_date, end_date, format } = cohort;
  const { survey: surveyNumber, cohort_week, is_final_bg_survey } = survey;

  const startDate = moment.utc(start_date);
  const endDate = moment.utc(end_date);
  const totalWeeks = endDate.diff(startDate, 'weeks') + 1;
  const isPtFormat = format === 'pt';

  const midWeeklySurvey = isPtFormat ? [7] : [Math.ceil(totalWeeks / 2)];
  const finalWeeklySurvey = isPtFormat ? [12] : [totalWeeks];
  const surveyWeek = isPtFormat ? parseInt(surveyNumber) : cohort_week;

  return R.cond([
    [() => parseInt(surveyNumber) === 0, R.always('Prework')],
    [() => parseInt(surveyNumber) === 2, R.always('Start')],

    [
      () => R.includes(parseInt(surveyNumber), midWeeklySurvey),
      R.always('Mid'),
    ],
    [
      () => R.includes(surveyWeek, finalWeeklySurvey) || is_final_bg_survey,
      R.always('Final'),
    ],
    [R.T, R.always('')],
  ])(survey);
};

/**
 * Gets the latest entry from a list of entries.
 *
 * This function filters the entries to include only those with a valid date,
 * then sorts them in descending order by date, and returns the latest entry.
 * If no valid entries are found, it returns an object with null date and name.
 *
 * @param {Array} entries - The list of entries to process.
 * @param {Object} entries[].date - The date of the entry.
 * @param {Object} entries[].name - The name of the entry.
 * @returns {Object} The latest entry with the most recent date, or an object with null date and name if no valid entries are found.
 */
const getLatestEntry = (entries) => {
  const validEntries = R.pipe(
    R.filter(
      (entry) =>
        entry.date && moment(entry.date, moment.ISO_8601, true).isValid()
    ),
    R.sortBy((entry) => -moment(entry.date).valueOf())
  )(entries);

  return validEntries.length ? validEntries[0] : { date: null, name: null };
};

/**
 * Retrieves the latest update for a given course and student.
 *
 * This function aggregates the latest progress, deliveries, lab attempts, and challenge attempts
 * for a specified course and student, and returns the most recent entry among them.
 *
 * @param {string} course - The course identifier.
 * @param {Object} student - The student object containing progress, deliveries, labs, and challenges data.
 * @returns {Object} The latest update entry with a date and name.
 */
const getLastCourseUpdate = (course, student) => {
  const latestProgress = R.pipe(
    R.pathOr([], [course, 'progress']),
    R.map((entry) => ({ date: entry.date, name: `[Unit]` }))
  )(student);

  const latestDelivery = R.pipe(
    R.pathOr([], [course, 'deliveries']),
    R.map((entry) => ({
      date: entry.submit_date,
      name: `[Assignment] ${entry.title}`,
    }))
  )(student);

  const latestLabAttempt = R.pipe(
    R.pathOr([], [course, 'labs']),
    R.chain((lab) =>
      R.map(
        (attempt) => ({ date: attempt.date, name: `[Lab] ${lab.title}` }),
        R.pathOr([], ['attempts'], lab)
      )
    )
  )(student);

  const latestChallengeAttempt = R.pipe(
    R.pathOr([], [course, 'challenges']),
    R.map((assessment) => ({
      date: assessment.date || null,
      name: `[Assessment] ${assessment.assessment_title}`,
    }))
  )(student);

  return getLatestEntry([
    ...latestProgress,
    ...latestDelivery,
    ...latestLabAttempt,
    ...latestChallengeAttempt,
  ]);
};

export { getSurveyType, getLastCourseUpdate };
