import {
  brown,
  green,
  grey,
  deepPurple,
  orange,
} from '@material-ui/core/colors';
const R = require('ramda');

const assignmentStatus = [
  { id: 'pending', name: 'Pending' },
  { id: 'not_delivered', name: 'Not Delivered' },
  { id: 'delivered', name: 'Delivered' },
  { id: 'delivered_late', name: 'Delivered Late' },
  { id: 'not_passed', name: 'Not Passed' },
];

const assignmentTypes = [
  { id: 'url_type', name: 'URL' },
  { id: 'file_type', name: 'File Upload' },
];

const attendance = [
  { id: 'late', name: 'Late' },
  { id: 'absent', name: 'Absent' },
  { id: 'excused', name: 'Excused' },
];

const avoidingSurchargeReasons = [
  { id: 'personal', name: 'Personal Reasons' },
  { id: 'job', name: 'Got A Job' },
  { id: 'bootcamp_canceled', name: 'Bootcamp was canceled' },
  { id: 'visa', name: 'Cannot get a visa' },
  { id: 'covid', name: 'Covid reasons' },
  { id: 'misplaced', name: 'Misplaced by Admissions' },
];

const campuses = [
  { id: 'ams', name: 'Amsterdam' },
  { id: 'bcn', name: 'Barcelona' },
  { id: 'ber', name: 'Berlin' },
  { id: 'lis', name: 'Lisbon' },
  { id: 'mad', name: 'Madrid' },
  { id: 'mex', name: 'Mexico City' },
  { id: 'mia', name: 'Miami' },
  { id: 'par', name: 'Paris' },
  { id: 'rmt', name: 'Remote' },
  { id: 'sao', name: 'Sao Paulo' },
];

const campusesTier2 = [
  { id: 'bod', name: 'Bordeaux' },
  { id: 'dus', name: 'Düsseldorf' },
  { id: 'lon', name: 'London' },
  { id: 'nyc', name: 'New York' },
  { id: 'tpa', name: 'Tampa' },
];

const campusesAll = R.pipe(
  R.concat(campusesTier2),
  R.sortBy(R.prop('id'))
)(campuses);

const careerServiceCategories = [
  { id: 'excluded', name: 'Excluded' },
  { id: 'searching', name: 'Searching' },
  { id: 'employed', name: 'Employed' },
  { id: 'pending', name: 'Pending' },
];

const careerServicePurposes = [
  { id: 'job_in_field', name: 'New Job In Field' },
  { id: 'back_to_job', name: 'Go Back To My Job' },
  { id: 'entrepreneur', name: 'Entrepreneurship' },
  { id: 'back_to_university', name: 'Go Back To University' },
  { id: 'personal_development', name: 'Personal Development' },
];

const careerhackOptedIn = [
  { id: true, name: 'Opt in' },
  { id: false, name: 'Not Opt in' },
];
// Be aware: sorted list
const careerServiceStatuses = [
  { id: 'pending', name: 'Pending' },
  { id: 'back_to_job', name: 'Back To Job', category: 'excluded' },
  {
    id: 'back_to_university',
    name: 'Back To University',
    category: 'excluded',
  },
  { id: 'entrepreneur', name: 'Entrepreneur', category: 'excluded' },
  {
    id: 'personal_development',
    name: 'Personal development',
    category: 'excluded',
  },
  {
    id: 'deferred_more_than_45d',
    name: 'Deferred Search >45 days',
    category: 'excluded',
  },
  { id: 'withdrew', name: 'Withdrew', category: 'excluded' },
  {
    id: 'not_graduated',
    name: 'Did not graduate from the course',
    category: 'excluded',
  },
  {
    id: 'not_graduated_cs',
    name: 'Did not graduate from Career services',
    category: 'excluded',
  },
  { id: 'not_eligible', name: 'Not Eligible', category: 'excluded' },
  { id: 'searching', name: 'Currently searching', category: 'searching' },
  {
    id: 'deferred_less_than_45d',
    name: 'Deferred Search <45 days',
    category: 'searching',
  },
  {
    id: 'deferred_more_than_45d_sc',
    name: 'Deferred Search >45 days (special circumstances)',
    category: 'searching',
  },
  { id: 'inactive', name: 'Inactive', category: 'searching' },
  {
    id: 'placement_not_successful',
    name: 'Placement not successful',
    category: 'searching',
  },
  {
    id: 'intervention_education',
    name: 'Intervention Education',
    category: 'searching',
  },
  {
    id: 'intervention_careers',
    name: 'Intervention Careers',
    category: 'searching',
  },
  {
    id: 'intervention_education_not_success',
    name: 'Intervention Education (not successful)',
    category: 'searching',
  },
  {
    id: 'intervention_careers_not_success',
    name: 'Intervention Careers (not successful)',
    category: 'searching',
  },
  { id: 'hired_in_field', name: 'Hired in field', category: 'employed' },
  {
    id: 'hired_out_of_field',
    name: 'Hired out of field',
    category: 'employed',
  },
  { id: 'internship', name: 'Internship', category: 'employed' },
  { id: 'freelance', name: 'Freelancer', category: 'employed' },
  {
    id: 'short_term',
    name: 'Short Contract and/or PT Position',
    category: 'employed',
  },
  { id: 'ironhack_employee', name: 'Ironhack Employee', category: 'employed' },
];

const careerServiceFinalStatuses = R.reject(
  R.propSatisfies(
    R.includes(R.__, ['pending', 'searching', 'not_graduated']),
    'id'
  ),
  careerServiceStatuses
);

const careerServiceStatusColors = {
  pending: grey[500],

  back_to_university: orange[500],
  personal_development: orange[500],
  back_to_job: orange[500],

  hired_in_field: green[500],
  hired_out_of_field: green[500],
  internship: green[500],
  ironhack_employee: green[500],
  freelance: green[500],
  short_term: green[500],

  not_graduated: brown[500],
  not_graduated_cs: brown[500],
  withdrew: brown[500],
  not_eligible: brown[500],
  deferred_more_than_45d: brown[500],

  searching: deepPurple[700],
  deferred_less_than_45d: deepPurple[700],
  deferred_more_than_45d_sc: deepPurple[700],

  inactive: grey[700],
  intervention_education_not_success: grey[700],
  intervention_careers_not_success: grey[700],
  placement_not_successful: grey[700],

  intervention_education: orange[900],
  intervention_careers: orange[900],
};

const changeOfMindReasonsFromOM = [
  {
    id: 'continue_learning',
    name: 'They decided to specialize their tech role with a specific course/training',
  },
  { id: 'change_careerpath', name: 'They decided to take another career path' },
  { id: 'promoted', name: 'They got promoted at their current job' },
  {
    id: 'personal_reasons',
    name: 'They need to focus on their family or personal illness',
  },
  {
    id: 'parental_leave',
    name: 'They are on a parental leave',
  },
  {
    id: 'finishing_studies',
    name: 'Finish their internship or studies',
  },
  {
    id: 'work_permit',
    name: 'They are waiting for work permit validation',
  },
  {
    id: 'break_or_holidays',
    name: 'Professional break, holidays, other',
  },
  {
    id: 'entrepreneur',
    name: 'They got the opportunity to start their own business',
  },
];

const changeOfMindReasonsFromStudent = [
  {
    id: 'continue_learning',
    name: 'I decided to specialize their tech role with a specific course/training',
  },
  { id: 'change_careerpath', name: 'I decided to take another career path' },
  { id: 'promoted', name: 'I got promoted at my current job' },
  {
    id: 'personal_reasons',
    name: 'I need to focus on my family or personal illness',
  },
  {
    id: 'parental_leave',
    name: "I'm on a parental leave",
  },
  {
    id: 'finishing_studies',
    name: 'Finish my internship or studies',
  },
  {
    id: 'work_permit',
    name: "I'm waiting for work permit validation",
  },
  {
    id: 'break_or_holidays',
    name: 'Professional break, holidays, other',
  },
  {
    id: 'entrepreneur',
    name: 'I got the opportunity to start my own business',
  },
  {
    id: 'special_circumstances',
    name: 'Family or personal illness, parental leave, finish my internship or studies, work permit validation',
  },
  {
    id: 'other',
    name: 'Professional break, holidays, other',
  },
  {
    id: 'course_preparation',
    name: "I don't feel ready to start looking for a job because of the course preparation",
  },
  {
    id: 'careerhack_preparation',
    name: "I don't feel ready to start looking for a job because of career hack preparation",
  },
];

const cirrCategories = [
  { id: 'hired', name: 'Hired' },
  { id: 'searching', name: 'Searching' },
  { id: 'out', name: 'Out' },
];

const cirrCodes = [
  { id: '1A', name: '1A' },
  { id: '1C', name: '1C' },
  { id: '2D', name: '2D' },
  { id: '3A', name: '3A' },
  { id: '3B', name: '3B' },
];

const cohortCreateDefaults = {
  ams: { campus: 'ams', language: 'en', price: { currency: 'EUR' } },
  bcn: { campus: 'bcn', language: 'es', price: { currency: 'EUR' } },
  ber: { campus: 'ber', language: 'en', price: { currency: 'EUR' } },
  lis: { campus: 'lis', language: 'pt', price: { currency: 'EUR' } },
  mad: { campus: 'mad', language: 'es', price: { currency: 'EUR' } },
  mex: { campus: 'mex', language: 'es', price: { currency: 'MXN' } },
  mia: { campus: 'mia', language: 'en', price: { currency: 'USD' } },
  par: { campus: 'par', language: 'fr', price: { currency: 'EUR' } },
  rmt: { campus: 'rmt', language: 'en', price: { currency: 'EUR' } },
  sao: { campus: 'sao', language: 'pt', price: { currency: 'BRL' } },
};

const cohortStatus = [
  { id: 'finished', name: 'Finished' },
  { id: 'ongoing', name: 'Ongoing' },
  { id: 'upcoming', name: 'Upcoming' },
];

const cohortTimezones = [
  { id: 'America/Chicago', name: 'America/Chicago' },
  { id: 'America/Mexico_City', name: 'America/Mexico City' },
  { id: 'America/Sao_Paulo', name: 'America/Sao Paulo' },
  { id: 'Europe/Madrid', name: 'Europe/Madrid' },
];

const currencies = [
  { id: 'EUR', name: 'EUR (Euro)' },
  { id: 'MXN', name: 'MXN (Mexican Peso)' },
  { id: 'USD', name: 'USD (US Dollar)' },
  { id: 'BRL', name: 'BRL (Brazilian Real)' },
  { id: 'COP', name: 'COP (Colombian Peso)' },
  { id: 'GBP', name: 'GBP (Pound sterling)' },
];

const dropReasons = [
  { id: 'bad_candidate', name: 'Bad Candidate' },
  { id: 'personal', name: 'Personal Reasons' },
  { id: 'finance', name: 'Financial Reasons' },
  { id: 'time', name: 'No Time' },
  { id: 'job', name: 'Got A Job' },
  { id: 'format', name: 'Bootcamp Format' },
  { id: 'methodology', name: 'Methodology' },
  { id: 'prework', name: 'Prework Not Finished' },
  { id: 'prework_difficulty', name: 'Prework Too Difficult' },
  { id: 'prework_copy', name: 'Copied Prework' },
  { id: 'visa', name: 'Cannot Get Visa' },
  { id: 'covid', name: 'Covid Reasons' },
  { id: 'course_cancelled', name: 'Bootcamp Cancelled' },
  { id: 'ghost', name: 'Ghost Student' },
  { id: 'misplaced', name: 'Misplaced by Admissions' },
  { id: 'other', name: 'Other Reason' },
];

const dropReasonsOld = [
  { id: 'drop_personal', name: 'Drop - Personal Reasons' },
  { id: 'drop_workload', name: 'Drop - Workload Reasons' },
  { id: 'drop_competitor', name: 'Drop - Going To A Competitor' },
  { id: 'drop_job', name: 'Drop - Student Got A Job' },
  { id: 'drop_format', name: 'Drop - Covid/Bootcamp Format' },
  { id: 'drop_finance', name: 'Drop - Financial Reasons' },
  { id: 'drop_unknown', name: 'Drop - Other/Unknown Reason' },
  { id: 'expel_behaviour', name: 'Expel - Behaviour Reasons' },
  { id: 'expel_level', name: 'Expel - Level Reasons' },
  { id: 'move', name: 'Move' },
];

const education = [
  { id: 'none', name: 'None' },
  { id: 'high_school', name: 'High School' },
  { id: 'bachelor', name: 'Bachelor Degree' },
  { id: 'master', name: 'Masters Degree' },
  { id: 'professional_school', name: 'Professional School' },
  { id: 'phd', name: 'PhD' },
];

const emailFamilies = [
  {
    id: 'course_welcome',
    name: 'Course Welcome',
    variants: ['track', 'campus'],
  },
  {
    id: 'course_welcome_preparation',
    name: 'Course Welcome M0',
  },
  {
    id: 'course_welcome_b2b',
    name: 'Course Welcome B2B',
    variants: ['has_prework'],
  },
  {
    id: 'career_welcome',
    name: 'Career Welcome',
    variants: ['track', 'campus'],
  },
  { id: 'prework_reminder', name: 'Prework Reminder' },
  { id: 'prework_reminder_b2b', name: 'Prework Reminder B2B' },
  {
    id: 'outcomes_form_reminder',
    name: 'Outcomes Form Reminder',
    variants: ['ordinal'],
  },
  {
    id: 'cohort_update_finance_notification',
    name: 'Cohort Update Finance Notification',
  },
  {
    id: 'outcomes_form_submission_notification',
    name: 'Outcomes Form Submission Notification',
  },
  {
    id: 'outcomes_form_never_submitted_notification',
    name: 'Outcomes Form Never Submitted Notification',
  },
  {
    id: 'outcomes_deferred_search_reminder',
    name: 'Outcomes Deferred Search Reminder',
  },
  {
    id: 'outcomes_placement_lost_reminder',
    name: 'Outcomes Placement Lost Reminder',
  },
  {
    id: 'outcomes_deferred_search_confirmation',
    name: 'Outcomes Deferred Search Confirmation',
  },
  {
    id: 'outcomes_placement_lost_confirmation',
    name: 'Outcomes Placement Lost Confirmation',
  },
  {
    id: 'potentially_at_risk_report',
    name: 'Students Potentially At Risk Report',
    variants: ['ordinal'],
  },
  {
    id: 'risk_level_notification',
    name: 'Student Risk Level Changed Notification',
    variants: ['risk_level'],
  },
  {
    id: 'careerhack_completion_reminder',
    name: 'Careerhack Completion Reminder',
  },
  {
    id: 'careerhack_completion_warning',
    name: 'Careerhack Completion Warning',
  },
  {
    id: 'careerhack_section_1_alert',
    name: 'Careerhack Section 1 SSM Alert',
  },
  {
    id: 'careerhack_section_2_alert',
    name: 'Careerhack Section 2 Student Alert',
  },
  {
    id: 'careerhack_section_2_notification',
    name: 'Careerhack Section 2 SSM Notfication',
  },
  {
    id: 'ai_chatbot_onboarding_b2c',
    name: 'AI Chatbot Onboarding B2C',
    variants: ['track'],
  },
  {
    id: 'ai_chatbot_onboarding_b2b',
    name: 'AI Chatbot Onboarding B2B',
  },
  {
    id: 'ai_chatbot_onboarding_prework',
    name: 'AI Chatbot Onboarding Prework',
  },
  {
    id: 'ai_chatbot_reminder',
    name: 'AI Chatbot Reminder',
  },
];

const formats = [
  { id: 'pt', name: 'Part Time' },
  { id: 'ft', name: 'Full Time' },
  { id: 'other', name: 'Other' },
];

const industries = [
  { id: 'accounting', name: 'Accounting' },
  { id: 'airlines_aviation', name: 'Airlines/Aviation' },
  {
    id: 'alternative_dispute_resolution',
    name: 'Alternative Dispute Resolution',
  },
  { id: 'alternative_medicine', name: 'Alternative Medicine' },
  { id: 'animation', name: 'Animation' },
  { id: 'apparel_and_fashion', name: 'Apparel & Fashion' },
  { id: 'architecture_and_planning', name: 'Architecture & Planning' },
  { id: 'arts_and_crafts', name: 'Arts And Crafts' },
  { id: 'automotive', name: 'Automotive' },
  { id: 'aviation_and_aerospace', name: 'Aviation & Aerospace' },
  { id: 'banking', name: 'Banking' },
  { id: 'biotechnology', name: 'Biotechnology' },
  { id: 'broadcast_media', name: 'Broadcast Media' },
  { id: 'building_materials', name: 'Building Materials' },
  {
    id: 'business_supplies_and_equipment',
    name: 'Business Supplies And Equipment',
  },
  { id: 'capital_markets', name: 'Capital Markets' },
  { id: 'chemicals', name: 'Chemicals' },
  { id: 'civic_and_social_organization', name: 'Civic & Social Organization' },
  { id: 'civil_engineering', name: 'Civil Engineering' },
  { id: 'commercial_real_estate', name: 'Commercial Real Estate' },
  { id: 'computer_and_network_security', name: 'Computer & Network Security' },
  { id: 'computer_games', name: 'Computer Games' },
  { id: 'computer_hardware', name: 'Computer Hardware' },
  { id: 'computer_networking', name: 'Computer Networking' },
  { id: 'computer_software', name: 'Computer Software' },
  { id: 'construction', name: 'Construction' },
  { id: 'consumer_electronics', name: 'Consumer Electronics' },
  { id: 'consumer_goods', name: 'Consumer Goods' },
  { id: 'consumer_services', name: 'Consumer Services' },
  { id: 'cosmetics', name: 'Cosmetics' },
  { id: 'dairy', name: 'Dairy' },
  { id: 'defense_and_space', name: 'Defense & Space' },
  { id: 'design', name: 'Design' },
  { id: 'education_management', name: 'Education Management' },
  { id: 'e_learning', name: 'E-Learning' },
  {
    id: 'electrical_electronic_manufacturing',
    name: 'Electrical/Electronic Manufacturing',
  },
  { id: 'entertainment', name: 'Entertainment' },
  { id: 'environmental_services', name: 'Environmental Services' },
  { id: 'events_services', name: 'Events Services' },
  { id: 'executive_office', name: 'Executive Office' },
  { id: 'facilities_services', name: 'Facilities Services' },
  { id: 'farming', name: 'Farming' },
  { id: 'financial_services', name: 'Financial Services' },
  { id: 'fine_art', name: 'Fine Art' },
  { id: 'fishery', name: 'Fishery' },
  { id: 'food_and_beverages', name: 'Food & Beverages' },
  { id: 'food_production', name: 'Food Production' },
  { id: 'fund_raising', name: 'Fund-Raising' },
  { id: 'furniture', name: 'Furniture' },
  { id: 'gambling_and_casinos', name: 'Gambling & Casinos' },
  { id: 'concrete', name: 'Concrete' },
  { id: 'government_administration', name: 'Government Administration' },
  { id: 'government_relations', name: 'Government Relations' },
  { id: 'graphic_design', name: 'Graphic Design' },
  { id: 'fitness', name: 'Fitness' },
  { id: 'higher_education', name: 'Higher Education' },
  { id: 'hospital_and_health_care', name: 'Hospital & Health Care' },
  { id: 'hospitality', name: 'Hospitality' },
  { id: 'human_resources', name: 'Human Resources' },
  { id: 'import_and_export', name: 'Import And Export' },
  {
    id: 'individual_and_family_services',
    name: 'Individual & Family Services',
  },
  { id: 'industrial_automation', name: 'Industrial Automation' },
  { id: 'information_services', name: 'Information Services' },
  {
    id: 'information_technology_and_services',
    name: 'Information Technology And Services',
  },
  { id: 'insurance', name: 'Insurance' },
  { id: 'international_affairs', name: 'International Affairs' },
  {
    id: 'international_trade_and_development',
    name: 'International Trade And Development',
  },
  { id: 'internet', name: 'Internet' },
  { id: 'investment_banking', name: 'Investment Banking' },
  { id: 'investment_management', name: 'Investment Management' },
  { id: 'judiciary', name: 'Judiciary' },
  { id: 'law_enforcement', name: 'Law Enforcement' },
  { id: 'law_practice', name: 'Law Practice' },
  { id: 'legal_services', name: 'Legal Services' },
  { id: 'legislative_office', name: 'Legislative Office' },
  { id: 'leisure_travel_and_tourism', name: 'Leisure, Travel & Tourism' },
  { id: 'libraries', name: 'Libraries' },
  { id: 'logistics_and_supply_chain', name: 'Logistics And Supply Chain' },
  { id: 'luxury_goods_and_jewelry', name: 'Luxury Goods & Jewelry' },
  { id: 'machinery', name: 'Machinery' },
  { id: 'management_consulting', name: 'Management Consulting' },
  { id: 'maritime', name: 'Maritime' },
  { id: 'market_research', name: 'Market Research' },
  { id: 'marketing_and_advertising', name: 'Marketing And Advertising' },
  {
    id: 'mechanical_or_industrial_engineering',
    name: 'Mechanical or Industrial Engineering',
  },
  { id: 'media_production', name: 'Media Production' },
  { id: 'medical_devices', name: 'Medical Devices' },
  { id: 'medical_practice', name: 'Medical Practice' },
  { id: 'mental_health_care', name: 'Mental Health Care' },
  { id: 'military', name: 'Military' },
  { id: 'mining_and_metals', name: 'Mining & Metals' },
  { id: 'motion_pictures_and_film', name: 'Motion Pictures And Film' },
  { id: 'museums_and_institutions', name: 'Museums And Institutions' },
  { id: 'music', name: 'Music' },
  { id: 'nanotechnology', name: 'Nanotechnology' },
  { id: 'newspapers', name: 'Newspapers' },
  {
    id: 'non_profit_organization_management',
    name: 'Non-Profit Organization Management',
  },
  { id: 'oil_and_energy', name: 'Oil & Energy' },
  { id: 'online_media', name: 'Online Media' },
  { id: 'outsourcing_offshoring', name: 'Outsourcing/Offshoring' },
  { id: 'package_freight_delivery', name: 'Package/Freight Delivery' },
  { id: 'packaging_and_containers', name: 'Packaging And Containers' },
  { id: 'paper_and_forest_products', name: 'Paper & Forest Products' },
  { id: 'performing_arts', name: 'Performing Arts' },
  { id: 'pharmaceuticals', name: 'Pharmaceuticals' },
  { id: 'philanthropy', name: 'Philanthropy' },
  { id: 'photography', name: 'Photography' },
  { id: 'plastics', name: 'Plastics' },
  { id: 'political_organization', name: 'Political Organization' },
  { id: 'primary_secondary_education', name: 'Primary/Secondary Education' },
  { id: 'printing', name: 'Printing' },
  {
    id: 'professional_training_and_coaching',
    name: 'Professional Training & Coaching',
  },
  { id: 'program_development', name: 'Program Development' },
  { id: 'public_policy', name: 'Public Policy' },
  {
    id: 'public_relations_and_communications',
    name: 'Public Relations And Communications',
  },
  { id: 'public_safety', name: 'Public Safety' },
  { id: 'publishing', name: 'Publishing' },
  { id: 'railroad_manufacture', name: 'Railroad Manufacture' },
  { id: 'ranching', name: 'Ranching' },
  { id: 'real_estate', name: 'Real Estate' },
  {
    id: 'recreational_facilities_and_services',
    name: 'Recreational Facilities And Services',
  },
  { id: 'religious_institutions', name: 'Religious Institutions' },
  { id: 'renewables_and_environment', name: 'Renewables & Environment' },
  { id: 'research', name: 'Research' },
  { id: 'restaurants', name: 'Restaurants' },
  { id: 'retail', name: 'Retail' },
  { id: 'security_and_investigations', name: 'Security And Investigations' },
  { id: 'semiconductors', name: 'Semiconductors' },
  { id: 'shipbuilding', name: 'Shipbuilding' },
  { id: 'sporting_goods', name: 'Sporting Goods' },
  { id: 'sports', name: 'Sports' },
  { id: 'staffing_and_recruiting', name: 'Staffing And Recruiting' },
  { id: 'supermarkets', name: 'Supermarkets' },
  { id: 'telecommunications', name: 'Telecommunications' },
  { id: 'textiles', name: 'Textiles' },
  { id: 'think_tanks', name: 'Think Tanks' },
  { id: 'tobacco', name: 'Tobacco' },
  { id: 'translation_and_localization', name: 'Translation And Localization' },
  {
    id: 'transportation_trucking_railroad',
    name: 'Transportation/Trucking/Railroad',
  },
  { id: 'utilities', name: 'Utilities' },
  {
    id: 'venture_capital_and_private_equity',
    name: 'Venture Capital & Private Equity',
  },
  { id: 'veterinary', name: 'Veterinary' },
  { id: 'warehousing', name: 'Warehousing' },
  { id: 'wholesale', name: 'Wholesale' },
  { id: 'wine_and_spirits', name: 'Wine And Spirits' },
  { id: 'wireless', name: 'Wireless' },
  { id: 'writing_and_editing', name: 'Writing And Editing' },
];

const labsStatus = [
  { id: 'pending', name: 'Pending' },
  { id: 'not_delivered', name: 'Not Delivered' },
  { id: 'delivered', name: 'Delivered' },
  { id: 'delivered_late', name: 'Delivered Late' },
  { id: 'not_passed', name: 'Not Passed' },
];

const languages = [
  { id: 'es', name: 'Spanish' },
  { id: 'en', name: 'English' },
  { id: 'fr', name: 'French' },
  { id: 'pt', name: 'Portuguese' },
  { id: 'de', name: 'German' },
];

const notGraduatedReasons = [
  { id: 'classes', name: 'Skipped more than the allowed number of classes' },
  { id: 'labs', name: 'Did not deliver the minimum number of labs' },
  { id: 'projects', name: 'Did not submit all projects' },
  { id: 'level', name: 'Did not reach minimum required level' },
  { id: 'code_of_conduct', name: 'Break of code of conduct' },
  { id: 'drop_with_content', name: 'Drop with content access' },
  { id: 'other', name: 'Other' },
];

const outcomeContractLength = [
  { id: 'permanent', name: 'Permanent' },
  { id: 'more_than_6_months', name: 'More Than 6 Months' },
  { id: 'between_3_and_6_months', name: 'Between 3 And 6 Months' },
  { id: 'less_than_3_months', name: 'Less Than 3 Months' },
];

const outcomeJobSource = [
  { id: 'ih_posted', name: 'Through a job opportunity shared by Ironhack' },
  { id: 'ih_networking', name: 'Through an Ironhack event/networking' },
  { id: 'ih_student_alumni', name: 'Through Ironhack student/alumni' },
  { id: 'ext_networking', name: 'Through Networking' },
  { id: 'ext_event', name: 'Through an event (not organized by Ironhack)' },
  { id: 'ext_linkedin', name: 'Through LinkedIn' },
  { id: 'ext_friend', name: 'Through a friend' },
  { id: 'ext_recruiter', name: 'Through a headhunter/recruiter' },
  { id: 'ext_online', name: 'Through a job offer I found online' },
  { id: 'other', name: 'Other' },
];

const outcomeJobType = [
  { id: 'part_time', name: 'Part Time' },
  { id: 'full_time', name: 'Full Time' },
];

const outcomeType = [
  { id: 'new_job', name: 'Secured A New Job' },
  { id: 'back_to_job', name: 'Back To Job' },
  { id: 'back_to_university', name: 'Back To University' },
  { id: 'entrepreneur', name: 'Entrepreneur' },
  { id: 'freelance', name: 'Freelance' },
];

const outcomeWeeklyHours = [
  { id: 'over_32', name: 'Full Time (>=32h/week)' },
  { id: 'between_20_and_31', name: 'Part Time (Between 20 and 31h/week)' },
  { id: 'between_10_and_19', name: 'Part Time (Between 10 and 19h/week)' },
  { id: 'less_than_10', name: 'Part Time (Less than 10h/week)' },
];

const rubricProjects = [
  { id: 'final', name: 'Final Project' },
  { id: 'midterm', name: 'Midterm Project' },
];

const rubricOutcomes = {
  // WD
  building_api: {
    description: 'Create a REST API having all CRUD operations',
  },
  building_front: {
    description:
      'Create and integrate the client-side application with the REST API',
  },
  auth_strategy: {
    description:
      'Implement Authentication and Authorization in a full-stack application',
  },
  handling_asynchrony: {
    description:
      'Handle asynchronous operations with Promises using then/catch or async/await',
  },
  styling: {
    description: "Style the application's UI using CSS or a CSS Framework",
  },
  backend_clean_code: {
    description:
      'Write clean, modular, and efficient code following best practices - backend',
  },
  frontend_clean_code: {
    description:
      'Write clean, modular, and efficient code following best practices - frontend',
  },
  backend_project_structure: {
    description:
      'Maintain a clean and logical project structure using proper naming and distribution of files and folders - backend',
  },
  frontend_project_structure: {
    description:
      'Maintain a clean and logical project structure using proper naming and distribution of files and folders - frontend',
  },
  deployment: {
    description:
      'Deploy a functioning the backend and the frontend to a Cloud service',
  },
  version_control: {
    description:
      'Save and track changes in the source code using Git and GitHub',
  },
  documentation: {
    description:
      "Document the application's features, configuration, and technical specifications",
  },
  // UX
  design_thinking: {
    description:
      'Use design thinking and iterative methods to best anticipate user needs',
  },
  user_research: {
    description:
      'Implement a user research strategy that combines qualitative and quantitative methods to collect information and generate insights',
  },
  identifying_and_solving_problems: {
    description: 'Identify the problem to be solved and the way to answer it',
  },
  business_analysis: {
    description:
      'Perform a competitive analysis on a specific industry in order to propose a market-driven solution',
  },
  generate_innovative_ideas: {
    description:
      'Generate alternative and innovative ideas for the desired solution and prioritise them to create a MVP',
  },
  information_architecture: {
    description:
      "Define an information architecture for the product that matches the users' mental models",
  },
  solution_development: {
    description: 'Develop an innovative solution to solve the problem',
  },
  creating_ui_design: {
    description:
      "Create a product's user interface design that respect the UI best practices and that matches the product/brand identity",
  },
  conducting_tests: {
    description:
      'Conduct desirability tests to determine how the user perceives the graphical choices',
  },
  assessing_digital_solution: {
    description:
      'Assess the feasibility of a digital solution, based on knowledge of development technologies',
  },
  // Java (Midterm project)
  data_modeling: {
    description: 'Properly create models and relationships',
  },
  // Java (Final project)
  testing: {
    description: 'Test the functionalities of the application',
  },
  error_handling: {
    description: 'Properly handle exceptions and errors (server & client side)',
  },
  clean_code: {
    description:
      'Write clean, modular, and efficient code following best practices.',
  },
  // Frontend (Midterm project)
  home_page: {
    description: 'Create the Home Page following provided designs',
  },
  project_page: {
    description: 'Create the Project Page following provided designs',
  },
  contact_page: {
    description: 'Create the Contact Page following provided designs',
  },
  responsive_design: {
    description: 'Create responsive pages',
  },
  managing_arrays: {
    description: 'Manage the array of projects',
  },
  dom_manipulation: {
    description: 'Generate content dynamically',
  },
  js_features: {
    description: 'Add JS functionalities',
  },
  // Frontend (Final project)
  frontend_vue_supabase: {
    description:
      'Create and integrate the client-side Vue application with the Supabase DB',
  },
  status_handling: {
    description: 'Implement status handling',
  },
  // Common
  team_work: {
    description:
      'Practice communication, critical thinking, professionalism and team-work skills',
  },
  communication: {
    description: 'Practice communication and task distribution',
  },
  pair_programming: {
    description: 'Pair Programming',
  },
  presentation: {
    description:
      'Build a presentation and perform a demo to deliver your final results, or the results of your group',
  },
  extra_features: {
    description: 'Enhance the application solution with extra features',
  },
  // React Native (64afb8f6031d28002aa33b3e)
  pixel_perfect: {
    description: 'Achieve pixel perfect implementation',
  },
  functionality: {
    description: 'Execute functionality implementation',
  },
  theme_library: {
    description: 'Successfully utilize Theme library components',
  },
  core_library: {
    description: 'Successfully utilize Core library functionalities',
  },
  navigation: {
    description: 'Develop efficient navigation',
  },
  endpoints: {
    description:
      'Correct usage of endpoints and application authentication (login, logout, password recovery)',
  },
  context_api: {
    description: 'Implement session management using Context API',
  },
  git_naming_conventions: {
    description: 'Use pre-defined Git naming conventions',
  },
  code_review: {
    description: 'Practice code review',
  },
  management_tools: {
    description: 'Use management tools and task lists',
  },
  scrum: {
    description: 'Practice Scrum and daily meetings',
  },
  collaboration_tools: {
    description: 'Use communication and collaboration tools',
  },
  custom_components: {
    description: 'Create custom components',
  },
  backend_connection: {
    description: 'Establish client connection to backend',
  },
  data_rendering: {
    description: 'Retrieve data from the backend and present in the frontend',
  },
  storage: {
    description: 'Use storage and management of data in states',
  },
  custom_hooks: {
    description: 'Create and implement custom hooks',
  },
};

const outcomesDomains = {
  // WD
  server_side: { name: 'Server-side Development' },
  databases: { name: 'Databases' },
  architecture: { name: 'Software Architecture' },
  web_apps: { name: 'Front-end Applications' },
  client_side: { name: 'Client-side Development' },
  intermediate_js: { name: 'Intermediate JavaScript' },
  advanced_js: { name: 'Advanced JavaScript' },
  code_quality: { name: 'Code Quality' },
  version_control: { name: 'Version Control' },
  // UX
  design_thinking: { name: 'Design Thinking' },
  user_research: { name: 'User Research' },
  research_synthesis: { name: 'User Research' },
  business_analysis: { name: 'Business Analysis' },
  ideation: { name: 'Ideation' },
  information_architecture: { name: 'Information Architecture' },
  interaction_design: { name: 'Interaction Design' },
  ui_design: { name: 'UI Design' },
  testing: { name: 'Testing' },
  design_implementation: { name: 'Design Implementation' },
  // Frontend
  state_framework: { name: 'State Framework' },
  // Common
  career_development: { name: 'Career Development' },
  soft_skills: { name: 'Soft skills' },
  project_management: { name: 'Project management' },
  // React Native (64afb8f6031d28002aa33b3e)
  app_design_functionality: { name: 'App Design & Functionality' },
  qa: { name: 'Quality Assurance' },
  app_functionality: { name: 'App Functionality' },
  frontend_backend_integration: { name: 'Frontend-backend Integration' },
};

const partTimeDays = [
  { id: '0101001', name: 'Monday, Wednesday And Saturdays' },
  { id: '0010101', name: 'Tuesday, Thursday And Saturdays' },
];

const partTimeHours = [
  { id: '09:00' },
  { id: '09:30' },
  { id: '10:00' },
  { id: '10:30' },
  { id: '11:00' },
  { id: '11:30' },
  { id: '12:00' },
  { id: '12:30' },
  { id: '13:00' },
  { id: '13:30' },
  { id: '14:00' },
  { id: '14:30' },
  { id: '15:00' },
  { id: '15:30' },
  { id: '16:00' },
  { id: '16:30' },
  { id: '17:00' },
  { id: '17:30' },
  { id: '18:00' },
  { id: '18:30' },
  { id: '19:00' },
  { id: '19:30' },
  { id: '20:00' },
  { id: '20:30' },
  { id: '21:00' },
  { id: '21:30' },
  { id: '22:00' },
  { id: '22:30' },
  { id: '23:00' },
  { id: '23:30' },
  { id: '00:00' },
];

const riskLevels = [
  {
    id: 'good',
    name: 'Good: Not at risk',
    short_name: 'Good',
  },
  {
    id: 'level_1',
    name: 'Level 1: Low severity, Struggling student',
    short_name: 'At risk level 1',
  },
  {
    id: 'level_2',
    name: 'Level 2: Moderate severity, Off-track student',
    short_name: 'At risk level 2',
  },
  {
    id: 'level_3',
    name: 'Level 3: High severity, At-risk/red flag',
    short_name: 'At risk level 3',
  },
  {
    id: 'potentially_at_risk',
    name: 'Potentially at risk',
    short_name: 'Potentially at risk',
    disabled: true,
  },
];

const riskLevelHardSkills = {
  wd: [
    { id: 'Terminal/OS', name: 'Terminal/OS' },
    { id: 'CSS', name: 'CSS' },
    { id: 'Express', name: 'Express' },
    { id: 'Github/Version Control', name: 'Github/Version Control' },
    { id: 'JavaScript', name: 'JavaScript' },
    { id: 'Node', name: 'Node' },
    { id: 'Frontend', name: 'Frontend' },
    { id: 'React', name: 'React' },
    { id: 'Database/MongoDB', name: 'Database/MongoDB' },
    { id: 'HTML', name: 'HTML' },
    { id: 'Backend', name: 'Backend' },
    { id: 'Other', name: 'Other' },
  ],
};

const riskLevelCheckpoints = [
  {
    id: 'prework_v1',
    name: 'M0 assessment check',
  },
  {
    id: 'course_v1',
    name: 'M1 assessment check',
  },
];

const riskLevelCriteria = [
  {
    id: 'one_done',
    name: 'If all assessments of M0 are undone',
  },
  {
    id: 'undone_or_underscored_less_than_3',
    name: 'If 3+ assessments from M0 are underscored or undone',
  },
  {
    id: 'assessment_passed_62e7c72e58a936004a6fdbd4',
    name: 'If M0 | Functions, data types, loops & conditionals is underscored or undone',
  },
  {
    id: 'assessment_passed_62fca1f4b4104add41a88b9e',
    name: 'If M1 | JS Foundations is underscored or undone',
  },
];

const salaryType = [
  { id: 'gross_year', name: 'Gross Per Year' },
  { id: 'hourly_rate', name: 'Hourly Rate' },
  { id: 'fixed', name: 'Fixed' },
  { id: 'net_month', name: 'Net Per Month' },
  { id: 'not_specified', name: 'Not Specified' },
  { id: 'unpaid', name: 'Unpaid' },
];

const surveyFeelings = [
  { id: 'lost', name: 'Lost' },
  { id: 'frustrated', name: 'Frustrated' },
  { id: 'neutral', name: 'Neutral' },
  { id: 'happy', name: 'Happy' },
  { id: 'excited', name: 'Excited' },
  { id: 'proud', name: 'Proud' },
];
const surveyQuestions = [
  {
    id: 'feelings',
    name: 'How do you feel this week?',
    metric: 'feelings',
    short_name: 'Feeling',
  },
  {
    id: 'prework',
    name: 'How well did the pre-work prepare you for your first week of class?',
    metric: 'questionScore',
    short_name: 'Prework',
  },
  {
    id: 'challenge',
    name: `How challenging did you find this week's content?`,
    metric: 'questionScore',
    short_name: 'Difficulty',
  },
  {
    id: 'instruction',
    name: 'How clear was the in-class instruction this week? ',
    metric: 'questionScore',
    short_name: 'Instructor Clarity',
  },
  {
    id: 'material',
    name: `How clear was this week's material in the student portal?`,
    metric: 'questionScore',
    short_name: 'Material Clarity',
  },
  {
    id: 'lab_preparation',
    name: 'How well did the theory taught in class prepare you for the labs this week?',
    metric: 'questionScore',
    short_name: 'Instruction/Lab Alignment',
  },
  {
    id: 'ai_confidence',
    name: 'Almost there, two remaining questions focused on your expectations and your current confidence',
    metric: 'questionScore',
    short_name: 'AI confidence',
  },
  {
    id: 'instructional_staff',
    name: 'How would you rate your overall experience with your instructional staff this week?',
    metric: 'questionScore',
    short_name: 'Instructor Experience',
  },
  {
    id: 'lead_teacher',
    name: 'How would you rate your overall experience with your Lead Teacher this week?',
    metric: 'questionScore',
    short_name: 'Lead Teacher Experience',
  },
  {
    id: 'teacher_assistant',
    name: 'How would you rate your overall experience with your Teacher Assistant this week?',
    metric: 'questionScore',
    short_name: 'Teacher Assistant Experience',
  },
  {
    id: 'ironhack',
    name: 'How satisfied are you with the overall Ironhack experience this week?',
    metric: 'questionScore',
    short_name: 'IH Experience',
  },
  { id: 'comments', name: 'Any comments?', short_name: 'Comment' },
  {
    id: 'overall_experience',
    metric: 'questionScore',
    name: 'How would you rate your student experience overall? (This includes non-academic activities, guest talks/workshops, and campus life) ',
    short_name: 'Student Experience',
  },
  {
    id: 'nps',
    metric: 'nps',
    name: 'How likely are you to recommend Ironhack to a friend, coworker, or family member?',
    short_name: 'NPS',
  },
  {
    id: 'v2_learning',
    name: 'How do you rate your overall learning progress in the bootcamp up to this point?',
    metric: 'questionScore',
    short_name: 'Learning',
  },
  {
    id: 'v2_support',
    name: `How do you assess the quality of the teaching and support you've received this week?`,
    metric: 'questionScore',
    short_name: 'Support',
  },
  {
    id: 'v2_tech_support',
    name: `How do you assess the quality of the teaching and support you've received this week?`,
    metric: 'questionScore',
    short_name: 'Tech support',
  },
  {
    id: 'v2_tech_support_satisfaction',
    name: `How satisfied are you with the Support provided?`,
    metric: 'questionScore',
    short_name: 'Tech support satisfaction',
  },
  {
    id: 'v2_sp_ai',
    name: 'How satisfied are you with the Student Portal and Ironhack’s learning AI tools (Feedback on Labs, Chatbot)?',
    metric: 'questionScore',
    short_name: 'AI Tools',
  },
  {
    id: 'v2_campus_experience',
    name: 'How do you evaluate your outside-of-classroom experience in our Campus?',
    metric: 'questionScore',
    short_name: 'Campus experience',
  },
  {
    id: 'v2_job_readiness',
    name: 'How confident do you feel to start looking for a job after graduation?',
    metric: 'questionScore',
    short_name: 'Job readiness',
  },
  {
    id: 'v2_expectations',
    name: 'How is the experience you’re having at Ironhack meeting your expectations?',
    metric: 'questionScore',
    short_name: 'Expectations',
  },
  {
    id: 'v2_nps_reason_promoter',
    name: 'What were the things you appreciated the most about the Ironhack experience?',
    metric: 'nps',
    short_name: 'NPS',
  },
  {
    id: 'v2_nps_reason_detractor',
    name: 'What were the things you believe we could improve about the Ironhack experience?',
    metric: 'nps',
    short_name: 'NPS',
  },
  {
    id: 'v2_sp_ai_reason_promoter',
    name: 'What were the things you appreciated the most about the Ironhack AI tools?',
    short_name: 'AI promoter',
  },
  {
    id: 'v2_sp_ai_reason_detractor',
    name: 'What were the things you believe we could improve about the Ironhack AI tools?',
    short_name: 'AI detractor',
  },
];

const techBackground = [
  { id: 'none', name: 'None' },
  { id: 'beginner', name: 'Beginner' },
  { id: 'intermediate', name: 'Intermediate' },
  { id: 'advanced', name: 'Advanced' },
  { id: 'professional', name: 'Professional' },
];

const tracks = [
  { id: 'wd', name: 'Web Development' },
  { id: 'da', name: 'Data Analytics' },
  { id: 'ux', name: 'UX/UI Design' },
  { id: 'cy', name: 'Cybersecurity' },
  { id: 'ai', name: 'AI' },
  { id: 'ml', name: 'DS & ML' },
  { id: 'dv', name: 'DevOps' },
  { id: 'mk', name: 'Marketing' },
  { id: 'sw', name: 'SW' },
  { id: 'df', name: 'DF' },
  { id: 'other', name: 'Other' },
];

const tracksB2b = [
  { id: 'wd', name: 'Web Development' },
  { id: 'da', name: 'Data Analytics' },
  { id: 'ux', name: 'UX/UI Design' },
  { id: 'cy', name: 'Cybersecurity' },
  { id: 'java', name: 'Java (Fullstack)' },
  { id: 'java_server', name: 'Java (Server only)' },
  { id: 'frontend', name: 'Frontend Development' },
  { id: 'other', name: 'Other' },
];

const b2bProjectTypes = [
  { id: 'reskilling', name: 'Reskilling' },
  { id: 'b2g', name: 'B2G' },
  { id: 'htd', name: 'HTD' },
  { id: 'onramp', name: 'Onramps' },
  { id: 'ai_school', name: 'Ai School' },
];

const jobSources = [
  {
    id: 'ih_posted',
    name: 'Through a job opportunity shared by Ironhack',
  },
  {
    name: 'Through an Ironhack event/networking',
    id: 'ih_networking',
  },
  {
    name: 'Through Ironhack student/alumni',
    id: 'ih_student_alumni',
  },
  {
    name: 'Through Networking',
    id: 'ext_networking',
  },
  {
    name: 'Through an event (not organized by Ironhack)',
    id: 'ext_event',
  },
  {
    name: 'Through LinkedIn',
    id: 'ext_linkedin',
  },
  {
    name: 'Through a friend',
    id: 'ext_friend',
  },
  {
    name: 'Through a headhunter/recruiter',
    id: 'ext_recruiter',
  },
  {
    name: 'Through a job offer I found online',
    id: 'ext_online',
  },
  { id: 'other', name: 'Other' },
];

const mostCommonPositions = [
  {
    name: 'Customer success Manager',
    id: 'customer_success_manager',
    track: 'ux',
  },
  {
    name: 'Digital Marketing Manager',
    id: 'digital_marketing_manager',
    track: 'ux',
  },
  {
    name: 'E-commerce Manager',
    id: 'e_commerce_manager',
    track: 'ux',
  },
  {
    name: 'Graphic Designer',
    id: 'graphic_designer',
    track: 'ux',
  },
  {
    name: 'Interactive Designer',
    id: 'interactive_designer',
    track: 'ux',
  },
  {
    name: 'Visual Designer',
    id: 'visual_designer',
    track: 'ux',
  },
  {
    name: 'Marketing Consultant',
    id: 'marketing_consultant',
    track: 'ux',
  },
  {
    name: 'Product Designer',
    id: 'product_designer',
    track: 'ux',
  },
  {
    name: 'Product Manager',
    id: 'product_manager',
    track: 'ux',
  },
  {
    name: 'Product Owner',
    id: 'product_owner',
    track: 'ux',
  },
  {
    name: 'UI Designer',
    id: 'ui_designer',
    track: 'ux',
  },
  {
    name: 'UX Consultant',
    id: 'ux_consultant',
    track: 'ux',
  },
  {
    name: 'UX Designer',
    id: 'ux_designer',
    track: 'ux',
  },
  {
    name: 'UX Explorer',
    id: 'ux_explorer',
    track: 'ux',
  },
  {
    name: 'UX Researcher',
    id: 'ux_researcher',
    track: 'ux',
  },
  {
    name: 'UX/UI Designer',
    id: 'ux_ui_designer',
    track: 'ux',
  },
  {
    name: 'UX/UI Consultant',
    id: 'ux_ui_consultant',
    track: 'ux',
  },
  {
    name: 'Web Designer',
    id: 'web_designer',
    track: 'ux',
  },
  {
    name: 'Web Developer',
    id: 'web_developer',
    track: 'ux',
  },
  {
    name: 'UX UI Graphic Designer',
    id: 'ux_ui_graphic_designer',
    track: 'ux',
  },
  {
    name: 'UX/UI Tester',
    id: 'ux_ui_tester',
    track: 'ux',
  },
  {
    name: 'Visual Developer',
    id: 'visual_developer',
    track: 'ux',
  },
  {
    name: 'Web Project Manager',
    id: 'web_project_manager',
    track: 'ux',
  },
  {
    name: 'Project Manager',
    id: 'project_manager',
    track: 'ux',
  },
  {
    name: 'UX Writer',
    id: 'ux_writer',
    track: 'ux',
  },
  {
    name: 'Copywriter',
    id: 'copywriter',
    track: 'ux',
  },
  {
    name: 'Growth Hacker',
    id: 'growth_hacker',
    track: 'ux',
  },
  {
    name: 'Growth Engineer',
    id: 'growth_engineer',
    track: 'ux',
  },
  {
    name: 'UX Architect',
    id: 'ux_architect',
    track: 'ux',
  },
  {
    name: 'Backend Developer',
    id: 'back_end_developer',
    track: 'wd',
  },
  {
    name: 'Frontend Developer',
    id: 'front_end_developer',
    track: 'wd',
  },
  {
    name: 'Full stack Developer',
    id: 'full_stack_developer',
    track: 'wd',
  },
  {
    name: 'Product Owner',
    id: 'product_owner',
    track: 'wd',
  },
  {
    name: 'Product Manager',
    id: 'product_manager',
    track: 'wd',
  },
  {
    name: 'Quality Assurance Developer',
    id: 'quality_assurance_developer',
    track: 'wd',
  },
  {
    name: 'Tech Consultant',
    id: 'tech_consultant',
    track: 'wd',
  },
  {
    name: 'Software Developer',
    id: 'software_developer',
    track: 'wd',
  },
  {
    name: 'Software Engineer',
    id: 'software_engineer',
    track: 'wd',
  },
  {
    name: 'Support Engineer',
    id: 'support_engineer',
    track: 'wd',
  },
  {
    name: 'UX/UI Designer',
    id: 'ux_ui_designer',
    track: 'wd',
  },
  {
    name: 'Web Designer',
    id: 'web_designer',
    track: 'wd',
  },
  {
    name: 'Web Engineer',
    id: 'web_engineer',
    track: 'wd',
  },
  {
    name: 'Web Integrator',
    id: 'web_integrator',
    track: 'wd',
  },
  {
    name: 'DevOps',
    id: 'devops',
    track: 'wd',
  },
  {
    name: 'QA Engineer',
    id: 'qa_engineer',
    track: 'wd',
  },
  {
    name: 'Visual Developer',
    id: 'visual_developer',
    track: 'wd',
  },
  {
    name: 'Technical Support Engineer',
    id: 'technical_support_engineer',
    track: 'wd',
  },
  {
    name: 'Web Project Manager',
    id: 'web_project_manager',
    track: 'wd',
  },
  {
    name: 'Webmaster',
    id: 'webmaster',
    track: 'wd',
  },
  {
    name: 'Web Developer',
    id: 'web_developer',
    track: 'wd',
  },
  {
    name: 'Technical Writer',
    id: 'technical_writer',
    track: 'wd',
  },
  {
    name: 'Mobile Developer',
    id: 'mobile_developer',
    track: 'wd',
  },
  {
    name: 'Blockchain Developer',
    id: 'blockchain_developer',
    track: 'wd',
  },
  {
    name: 'QA Analyst/Tester',
    id: 'qa_analyst_tester',
    track: 'wd',
  },
  {
    name: 'Customer Success Manager',
    id: 'customer_success_manager',
    track: 'wd',
  },
  {
    name: 'Application Developer',
    id: 'application_developer',
    track: 'wd',
  },
  {
    name: 'Business Intelligence Analyst',
    id: 'business_intelligence_analyst',
    track: 'da',
  },
  {
    name: 'Business Analyst',
    id: 'business_analyst',
    track: 'da',
  },
  {
    name: 'Data Analyst',
    id: 'data_analyst',
    track: 'da',
  },
  {
    name: 'Data Analyst consultant',
    id: 'data_analyst_consultant',
    track: 'da',
  },
  {
    name: 'Data Visualization Specialist',
    id: 'data_visualization_specialist',
    track: 'da',
  },
  {
    name: 'Data Scientist',
    id: 'data_scientist',
    track: 'da',
  },
  {
    name: 'Database Developer',
    id: 'database_developer',
    track: 'da',
  },
  {
    name: 'Data Engineer',
    id: 'data_engineer',
    track: 'da',
  },
  {
    name: 'Machine Learning Engineer',
    id: 'machine_learning_engineer',
    track: 'da',
  },
  {
    name: 'Project Manager',
    id: 'project_manager',
    track: 'da',
  },
  {
    name: 'Python Developer',
    id: 'python_developer',
    track: 'da',
  },
  {
    name: 'Python Engineer',
    id: 'python_engineer',
    track: 'da',
  },
  {
    name: 'Business Analyst',
    id: 'business_analyst',
    track: 'da',
  },
  {
    name: 'Technical Support Engineer',
    id: 'technical_support_engineer',
    track: 'da',
  },
  {
    name: 'Business Intelligence',
    id: 'business_intelligence',
    track: 'da',
  },
  {
    name: 'Solutions Analyst',
    id: 'solutions_analyst',
    track: 'da',
  },
  {
    name: 'Implementation Analyst',
    id: 'implementation_analyst',
    track: 'da',
  },
  {
    name: 'Project Manager',
    id: 'project_manager',
    track: 'da',
  },
  {
    name: 'Marketing Product Analyst',
    id: 'marketing_product_analyst',
    track: 'da',
  },
  {
    name: 'Customer insights analyst',
    id: 'customer_insights_analyst',
    track: 'da',
  },
  {
    name: 'BI consultant',
    id: 'business_intelligence_consultant',
    track: 'da',
  },
  {
    name: 'Product Data Analyst',
    id: 'product_data_analyst',
    track: 'da',
  },
  {
    name: 'Operations Analyst',
    id: 'operations_analyst',
    track: 'da',
  },
  {
    name: 'Sales Analyst',
    id: 'sales_analyst',
    track: 'da',
  },
  {
    name: 'HR Partner Analyst',
    id: 'hr_Partner_analyst',
    track: 'da',
  },
  {
    name: 'HR Analyst',
    id: 'hr_analyst',
    track: 'da',
  },
  {
    name: 'Cybersecurity Consultant',
    id: 'cybersecurity_consultant',
    track: 'cy',
  },
  {
    name: 'SOC Security Analyst',
    id: 'soc_security_analyst',
    track: 'cy',
  },
  {
    name: 'IT Security Consultant',
    id: 'it_security_consultant',
    track: 'cy',
  },
  {
    name: 'Security Engineer',
    id: 'security_engineer',
    track: 'cy',
  },
  {
    name: 'Jr. Pentester',
    id: 'jr_pentester',
    track: 'cy',
  },
  {
    name: 'Site Reliability Engineer',
    id: 'site_reliability_engineer',
    track: 'cy',
  },
  {
    name: 'Threat and Cybersecurity Analyst',
    id: 'cybersecurity_analyst',
    track: 'cy',
  },
  {
    name: 'Project Manager for Cybersecurity',
    id: 'cybersecurity_pm',
    track: 'cy',
  },
];

export {
  assignmentStatus,
  assignmentTypes,
  attendance,
  b2bProjectTypes,
  avoidingSurchargeReasons,
  campuses,
  campusesTier2,
  campusesAll,
  careerhackOptedIn,
  careerServiceCategories,
  careerServicePurposes,
  careerServiceStatuses,
  careerServiceFinalStatuses,
  careerServiceStatusColors,
  changeOfMindReasonsFromOM,
  changeOfMindReasonsFromStudent,
  cirrCategories,
  cirrCodes,
  cohortCreateDefaults,
  cohortStatus,
  cohortTimezones,
  currencies,
  dropReasons,
  dropReasonsOld,
  education,
  emailFamilies,
  formats,
  jobSources,
  industries,
  labsStatus,
  languages,
  mostCommonPositions,
  notGraduatedReasons,
  outcomeContractLength,
  outcomeJobSource,
  outcomeJobType,
  outcomeType,
  outcomeWeeklyHours,
  rubricOutcomes,
  rubricProjects,
  outcomesDomains,
  partTimeDays,
  partTimeHours,
  riskLevels,
  riskLevelHardSkills,
  riskLevelCheckpoints,
  riskLevelCriteria,
  salaryType,
  surveyFeelings,
  surveyQuestions,
  techBackground,
  tracks,
  tracksB2b,
};
