import React from 'react';
import { useSelector } from 'react-redux';
import { Layout, Menu } from 'react-admin';
import AppBar from './AppBar';
import { darkTheme, lightTheme } from './themes';
import { usePageTracking } from '../hooks/usePageTracking';

const MyMenu = (props) => <Menu {...props} dense={false} />;

const CustomLayout = (props) => {
  const theme = useSelector((state) =>
    state.theme === 'dark' ? darkTheme : lightTheme
  );
  usePageTracking();
  return <Layout {...props} appBar={AppBar} menu={MyMenu} theme={theme} />;
};

export default CustomLayout;
