import React from 'react';
import * as R from 'ramda';

import {
  BooleanField,
  FunctionField,
  ImageField,
  Labeled,
  SelectField,
  TextField,
  UrlField,
  useQuery,
} from 'react-admin';
import { authServer } from 'auth/keycloak';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';

import {
  cohortTimezones,
  formats,
  languages,
  partTimeDays,
  partTimeHours,
  tracksB2b,
  b2bProjectTypes,
} from 'data';
import { DateField } from 'resources/common';

const QuickAccessButton = (props) => {
  const { record: cohort, course, type, label } = props;
  const courseId = R.pathOr('', [course, 'openedx'], cohort);
  const url =
    type === 'studio'
      ? `${process.env.REACT_APP_STUDIO_BASE_URL}/course/${courseId}`
      : `${process.env.REACT_APP_PREVIEW_BASE_URL}/lms/courses/${courseId}`;
  return (
    <Button
      size="small"
      color="primary"
      target="_blank"
      variant="outlined"
      href={url}
    >
      <OpenInNew style={{ paddingRight: '0.2em' }} />
      {label}
    </Button>
  );
};

const CompanyLogos = ({ record }) => (
  <>
    {R.map(({ url, file_name }) => (
      <ImageField
        key={url}
        source="url"
        title="file_name"
        record={{ url, file_name }}
        label="title"
      />
    ))(record.banner.company_logos)}
  </>
);

const InformationTab = ({ record }) => {
  const courseWelcomeEmailTemplateId =
    record?.course?.email_templates?.welcome_email_template_id;
  const { data: emailTemplate, loading: emailTemplateLoading } = useQuery({
    type: 'getOne',
    resource: 'emails',
    payload: {
      pagination: {
        page: 1,
        perPage: 10,
      },
      sort: {
        field: 'id',
        order: 'ASC',
      },
      id: courseWelcomeEmailTemplateId,
    },
  });

  return (
    <Box p="16px">
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Cohort
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Labeled label="Company">
            <TextField source="company" record={record} />
          </Labeled>
        </Grid>
        <Grid item xs={12} md={2}>
          <Labeled label="Track">
            <SelectField source="track" choices={tracksB2b} record={record} />
          </Labeled>
        </Grid>
        <Grid item xs={12} md={2}>
          <Labeled label="Type of project">
            <SelectField
              source="project_type"
              choices={b2bProjectTypes}
              record={record}
            />
          </Labeled>
        </Grid>
        <Grid item xs={12} md={2}>
          <Labeled label="Format">
            <SelectField source="format" choices={formats} record={record} />
          </Labeled>
        </Grid>
        <Grid item xs={12} md={2}>
          <Labeled label="Timezone">
            <SelectField
              source="timezone"
              choices={cohortTimezones}
              record={record}
            />
          </Labeled>
        </Grid>
        <Grid item xs={12} md={2}>
          <Labeled label="Language">
            <SelectField
              source="language"
              choices={languages}
              record={record}
            />
          </Labeled>
        </Grid>
        <Grid item xs={12} md={2}>
          <Labeled label="Week hours">
            <TextField source="week_hours" record={record} />
          </Labeled>
        </Grid>
        <Grid item xs={12}>
          <Box mt="2em">
            <Typography variant="h5" gutterBottom>
              Quick Access
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          {R.has('prework', record) && (
            <>
              <Grid item xs={12}>
                <Labeled label="Prework">
                  <QuickAccessButton
                    record={record}
                    course="prework"
                    type="studio"
                    label="Studio"
                  />
                </Labeled>
              </Grid>
              <Grid item xs={12}>
                <QuickAccessButton
                  record={record}
                  course="prework"
                  type="preview"
                  label="Preview"
                />
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid item xs={12}>
            <Labeled label="Main Course">
              <QuickAccessButton
                record={record}
                course="course"
                type="studio"
                label="Studio"
              />
            </Labeled>
          </Grid>
          <Grid item xs={12}>
            <QuickAccessButton
              record={record}
              course="course"
              type="preview"
              label="Preview"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          {R.has('career_services', record) && (
            <>
              <Grid item xs={12}>
                <Labeled label="Career Services">
                  <QuickAccessButton
                    record={record}
                    course="career_services"
                    type="studio"
                    label="Studio"
                  />
                </Labeled>
              </Grid>
              <Grid item xs={12}>
                <QuickAccessButton
                  record={record}
                  course="career_services"
                  type="preview"
                  label="Preview"
                />
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <Box mt="2em">
            <Typography variant="h5" gutterBottom>
              Dates
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Labeled label="Start Date">
            <DateField source="start_date" record={record} />
          </Labeled>
        </Grid>
        <Grid item xs={12} md={6}>
          <Labeled label="End Date">
            <DateField source="end_date" record={record} />
          </Labeled>
        </Grid>
        {R.propEq('format', 'pt', record) && (
          <Grid item xs={12} md={3}>
            <Labeled label="Part Time Days">
              <SelectField
                source="timetable.days"
                choices={partTimeDays}
                record={record}
              />
            </Labeled>
          </Grid>
        )}
        {R.propEq('format', 'pt', record) && (
          <Grid item xs={12} md={3}>
            <Labeled label="Weekday Start Time">
              <SelectField
                source="timetable.time.0.start_time"
                choices={partTimeHours}
                optionText="id"
                record={record}
              />
            </Labeled>
          </Grid>
        )}
        {R.propEq('format', 'pt', record) && (
          <Grid item xs={12} md={2}>
            <Labeled label="Weekday End Time">
              <SelectField
                source="timetable.time.0.end_time"
                choices={partTimeHours}
                optionText="id"
                record={record}
              />
            </Labeled>
          </Grid>
        )}
        {R.propEq('format', 'pt', record) && (
          <Grid item xs={12} md={2}>
            <Labeled label="Weekend Start Time">
              <SelectField
                source="timetable.time.1.start_time"
                choices={partTimeHours}
                optionText="id"
                record={record}
              />
            </Labeled>
          </Grid>
        )}
        {R.propEq('format', 'pt', record) && (
          <Grid item xs={12} md={2}>
            <Labeled label="Weekend End Time">
              <SelectField
                source="timetable.time.1.end_time"
                choices={partTimeHours}
                optionText="id"
                record={record}
              />
            </Labeled>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box mt="2em">
            <Typography variant="h5" gutterBottom>
              Staff
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={2}>
          <Labeled label="PM">
            <FunctionField
              source="staff.pm"
              record={record}
              render={R.pipe(
                R.pathOr({}, ['staff', 'pm']),
                R.props(['first_name', 'last_name']),
                R.join(' ')
              )}
            />
          </Labeled>
        </Grid>
        <Grid item xs={12} md={2}>
          <Labeled label="Number of TAs">
            <TextField source="staff.ta_amount" record={record} />
          </Labeled>
        </Grid>
        <Grid item xs={12} md={2}>
          <Labeled label="Teachers">
            <FunctionField
              record={record}
              render={R.pipe(
                R.pathOr([], ['staff', 'teachers']),
                R.map(
                  R.pipe(
                    R.props(['first_name', 'last_name']),
                    R.join(' '),
                    R.trim
                  )
                ),
                R.join(', '),
                R.trim
              )}
            />
          </Labeled>
        </Grid>
        <Grid item xs={12}>
          <Box mt="2em" mb="10px">
            <Typography variant="h5" gutterBottom>
              Courses
            </Typography>
          </Box>
        </Grid>
        <Box flex={1} flexDirection="row" mr={{ md: '2em' }}>
          {R.has('prework', record) && (
            <Box>
              <Box mb="10px">
                <Typography variant="h6" gutterBottom>
                  Prework
                </Typography>
              </Box>
              <Box>
                <Labeled label="Student Portal Title">
                  <TextField source="prework.title" record={record} />
                </Labeled>
              </Box>
              <Box>
                <Labeled label="OpenEdx Id">
                  <TextField source="prework.openedx" record={record} />
                </Labeled>
              </Box>
            </Box>
          )}
        </Box>
        <Box flex={1} flexDirection="row" mr={{ md: '2em' }}>
          <Box mb="10px">
            <Typography variant="h6" gutterBottom>
              Main Course
            </Typography>
          </Box>
          <Box>
            <Labeled label="Student Portal Title">
              <TextField source="course.title" record={record} />
            </Labeled>
          </Box>
          <Box>
            <Labeled label="OpenEdx Id">
              <TextField source="course.openedx" record={record} />
            </Labeled>
          </Box>
        </Box>
        <Box flex={1} flexDirection="row" mr={{ md: '2em' }}>
          {R.has('career_services', record) && (
            <Box>
              <Box mb="10px">
                <Typography variant="h6" gutterBottom>
                  Career Services
                </Typography>
              </Box>
              <Box>
                <Labeled label="Student Portal Title">
                  <TextField source="career_services.title" record={record} />
                </Labeled>
              </Box>
              <Box>
                <Labeled label="OpenEdx Id">
                  <TextField source="career_services.openedx" record={record} />
                </Labeled>
              </Box>
            </Box>
          )}
        </Box>
      </Grid>
      {R.propEq('track', 'cy', record) && (
        <>
          <Grid item xs={12}>
            <Box mt="2em">
              <Typography variant="h5" gutterBottom>
                External IDs
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <Labeled label="Cybint Access Code">
              <TextField
                source="external_ids.cybint_access_code"
                record={record}
              />
            </Labeled>
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Box mt="2em">
          <Typography variant="h5" gutterBottom>
            Cohort specifics
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box display={{ sm: 'block', md: 'flex' }}>
          {authServer.isRoles(['admin', 'gm', 'pm', 'outcomes']) &&
            !emailTemplateLoading && (
              <Box flexBasis="32%" mr={{ md: '2em' }}>
                <Labeled label="Course Welcome Email">
                  <Typography>
                    {R.ifElse(
                      R.has('name'),
                      (template) =>
                        `${template.name}${
                          template.language
                            ? ` - ${template.language.toUpperCase()}`
                            : ''
                        }`,
                      R.always('--')
                    )(emailTemplate)}
                  </Typography>
                </Labeled>
              </Box>
            )}
          <Box flexBasis="68%">
            <Labeled label="Link to FAQ">
              {record.faq_url ? (
                <UrlField source="faq_url" record={record} />
              ) : (
                <Typography>--</Typography>
              )}
            </Labeled>
          </Box>
        </Box>
        <Box flexBasis="68%">
          <Labeled label="Accredible design ID">
            {record.external_ids?.accredible_design_id ? (
              <TextField
                source="external_ids.accredible_design_id"
                record={record}
              />
            ) : (
              <Typography>--</Typography>
            )}
          </Labeled>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Labeled label="Text for client logos">
          <TextField source="banner.copy" record={record} emptyText="--" />
        </Labeled>
      </Grid>
      <Grid item xs={12}>
        <Labeled label="Client logos">
          {R.pathSatisfies(R.length, ['banner', 'company_logos'], record) ? (
            <CompanyLogos record={record} />
          ) : (
            <Typography>--</Typography>
          )}
        </Labeled>
      </Grid>
      {authServer.isRoles(['admin']) && (
        <Grid item xs={12} md={6}>
          <Labeled label="AI Chatbot activated">
            <BooleanField
              source="feature_flags.ai_chatbot_enabled"
              record={
                R.hasPath(['feature_flags', 'ai_chatbot_enabled'], record)
                  ? record
                  : { feature_flags: { ai_chatbot_enabled: false } }
              }
            />
          </Labeled>
          <Labeled label="Ai feedback enabled">
            <BooleanField
              record={
                R.hasPath(['feature_flags', 'labs_enabled'], record)
                  ? record
                  : { feature_flags: { labs_enabled: false } }
              }
              source="feature_flags.labs_enabled"
            />
          </Labeled>
        </Grid>
      )}
    </Box>
  );
};

export default InformationTab;
