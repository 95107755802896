import React from 'react';
import * as R from 'ramda';
import { Chip, makeStyles } from '@material-ui/core';
import { green, grey, red } from '@material-ui/core/colors';
import moment from 'moment-timezone';

const useListStyles = makeStyles({
  today: { color: 'white', background: green[900] },
  yesterday: { color: 'black', background: green[500] },
  no_progress: { color: 'black', background: red[400] },
  default: { color: 'black', background: grey[500] },
});
const LastProgressDateChip = ({ course, record }) => {
  const classes = useListStyles();

  const lastProgress = moment(
    R.path([course, 'last_progress', 'date'], record)
  );
  const today = moment().startOf('day');
  const yesterday = moment().subtract(1, 'days').startOf('day');

  const getLabelAndClass = R.cond([
    [
      () => lastProgress.isSame(today, 'day'),
      () => ({ label: 'Today', className: classes.today }),
    ],
    [
      () => lastProgress.isSame(yesterday, 'day'),
      () => ({ label: 'Yesterday', className: classes.yesterday }),
    ],
    [
      () => R.isNil(R.path([course, 'last_progress', 'date'], record)),
      () => ({ label: 'No progress done', className: classes.no_progress }),
    ],
    [
      R.T,
      () => ({
        label: lastProgress.format('Do MMMM YYYY'),
        className: classes.default,
      }),
    ],
  ]);

  const { label, className } = getLabelAndClass();

  return <Chip label={label} className={className} sortable />;
};

export default LastProgressDateChip;
