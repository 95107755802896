import React from 'react';
import * as R from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import { Tab } from 'react-admin';

import { NavigableTabs } from 'resources/common';

import {
  ByAssessmentsSubtab,
  ByAssignmentsSubtab,
  ByLastProgressSubtab,
  ByLabsSubtab,
  ByStudentsSubtab,
  ByStudentsProjectSubtab,
} from './subtabs';

function ProgressTab(props) {
  const { record: cohort, course } = props;
  const classes = makeStyles({
    selected: { background: 'rgb(201, 206, 234)' },
  })();

  return (
    <NavigableTabs
      {...props}
      variant="fullWidth"
      matchUrl={R.pipe(R.split('/'), R.take(6), R.join('/'))}
    >
      <Tab
        key="students"
        label="By Students"
        path="students"
        classes={{ selected: classes.selected }}
      >
        <ByStudentsSubtab course={course} />
      </Tab>
      <Tab
        key="assignments"
        label="By Assignments"
        path="assignments"
        classes={{ selected: classes.selected }}
      >
        <ByAssignmentsSubtab course={course} />
      </Tab>
      {R.hasPath([course, 'labs'], cohort) && (
        <Tab
          key="LABS"
          label="By Labs"
          path="labs"
          classes={{ selected: classes.selected }}
        >
          <ByLabsSubtab course={course} />
        </Tab>
      )}
      <Tab
        key="assessments"
        label="By Assessments"
        path="assessments"
        classes={{ selected: classes.selected }}
      >
        <ByAssessmentsSubtab course={course} />
      </Tab>
      <Tab
        key="course_progress"
        label="By Last Progress"
        path="last_progress"
        classes={{ selected: classes.selected }}
      >
        <ByLastProgressSubtab course={course} />
      </Tab>
      {R.hasPath([course, 'projects'], cohort) && (
        <Tab
          key="projects"
          label="Project Rubrics"
          path="projects"
          classes={{ selected: classes.selected }}
        >
          <ByStudentsProjectSubtab course={course} />
        </Tab>
      )}
    </NavigableTabs>
  );
}

export default ProgressTab;
