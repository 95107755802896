import mixpanel from 'mixpanel-browser';

/**
 * The Mixpanel token used for analytics.
 * This token is retrieved from the environment variable `REACT_APP_MIXPANEL_TOKEN`.
 *
 * @constant {string} MIXPANEL_TOKEN
 */
const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;
const isProduction = process.env.REACT_APP_MIXPANEL_ENV === 'production';

if (MIXPANEL_TOKEN && !mixpanel.__initialized) {
  mixpanel.init(MIXPANEL_TOKEN, {
    debug: !isProduction,
    persistence: 'localStorage',
  });
  mixpanel.__initialized = true;
}

export default mixpanel;
