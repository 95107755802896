import React from 'react';
import * as R from 'ramda';
import moment from 'moment-timezone';
import {
  BooleanInput,
  Edit,
  Error,
  FormDataConsumer,
  FormWithRedirect,
  ImageField,
  ImageInput,
  Loading,
  minValue,
  number,
  NumberInput,
  regex,
  required,
  SelectInput,
  TextInput,
  Toolbar,
  useQuery,
  SelectArrayInput,
} from 'react-admin';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { DateRange } from '@material-ui/icons';

import { DateInput } from 'resources/common/DatePickers';
import { authServer } from 'auth/keycloak';
import {
  b2bProjectTypes,
  cohortTimezones,
  formats,
  languages,
  partTimeDays,
  partTimeHours,
  tracksB2b,
} from 'data';
import mutators from './mutators';
import CohortB2bSaveButton from './CohortB2bSaveButton';

const CohortTitle = ({ record }) =>
  `${record.campus.toUpperCase()} ${record.track.toUpperCase()} ${record.format.toUpperCase()} ${
    record.start_date.split('T')[0]
  } ${record.language.toUpperCase()}`;

const validateRequired = [required('Required')];
const validateNumbers = [number(), minValue(0)];
const validateRequiredNumbers = R.concat(validateRequired, validateNumbers);
const validateUrl = regex(
  /^(ftp|http|https):\/\/[^ "]+$/,
  'Invalid URL. Be sure to include the `http://` or `https://` protocol to the URL'
);

const validateCohortEdit = (values) => {
  const errors = {};

  const emptyPartTimeDays = R.pathEq(['timetable', 'days'], '0000000', values);
  if (emptyPartTimeDays) {
    errors.timetable = {
      days: ['Required'],
    };
  }

  const wrongStartDate =
    moment(values.start_date).toDate() > moment(values.end_date).toDate();
  if (wrongStartDate) {
    errors.start_date = ['The start date cannot be later than the end date'];
    errors.end_date = ['The end date cannot be earlier than the start date'];
  }

  const wrongPartTimeStartDate = R.allPass([
    R.propEq('format', 'pt'),
    R.has('start_date'),
    R.hasPath(['timetable', 'days']),
    (values) => {
      const startDay = moment(values.start_date).day();
      const partTimeDays = R.path(['timetable', 'days'], values);
      return partTimeDays[startDay] !== '1';
    },
  ])(values);
  if (wrongPartTimeStartDate) {
    const startDay =
      R.path(['timetable', 'days', 1], values) === '1' ? 'Monday' : 'Tuesday';
    errors.start_date = [`The cohort must start on a ${startDay}`];
  }

  const wrongDiscount =
    R.path(['price', 'discount'], values) > R.path(['price', 'amount'], values);
  if (wrongDiscount) {
    errors.price = {
      discount: ['Cannot be larger than the cohort price'],
    };
  }

  return errors;
};

const GROUPS = { pm: '8b9f3f55-1147-409e-adb0-7e5f1f6a9d03' };

const createGroupQuery = (pagination, group) => ({
  type: 'getList',
  resource: 'users',
  payload: {
    filter: { group: GROUPS[group], with_groups: false },
    pagination,
  },
});

const useStyles = makeStyles({
  dropZone: {
    borderStyle: 'dashed',
    borderColor: 'rgba(0, 0, 0, 0.54)',
  },
});

const CohortB2bEditForm = (props) => {
  const pms = useQuery(createGroupQuery({ page: 1, perPage: 100 }, 'pm'));
  const teachers = useQuery(createGroupQuery({ page: 1, perPage: 200 }, 'teacher'));
  const classes = useStyles();

  const { data: emails, loading: emailsLoading } = useQuery({
    type: 'getList',
    resource: 'emails',
    payload: {
      pagination: {
        page: 1,
        perPage: 100,
      },
      sort: {
        field: 'name',
        order: 'ASC',
      },
      filter: {
        family: 'course_welcome_b2b',
      },
    },
  });

  if (pms.loading || teachers.loading)
    return (
      <Box p="16px">
        <Loading />
      </Box>
    );

  if (pms.error || teachers.error)
    return (
      <Box p="16px">
        <Error />
      </Box>
    );

  const fieldsConfig = {
    track: {
      validate: validateRequired,
      disabled: false,
    },
    format: {
      validate: validateRequired,
      disabled: false,
    },
    timezone: {
      validate: validateRequired,
      disabled: true,
    },
    language: {
      validate: validateRequired,
      disabled: !authServer.isAllRoles(['pm', 'b2b']),
    },
    start_date: {
      validate: validateRequired,
      disabled: !authServer.isAllRoles(['pm', 'b2b']),
    },
    end_date: {
      validate: validateRequired,
      disabled: !authServer.isAllRoles(['pm', 'b2b']),
    },
    'timetable.days': {
      validate: validateRequired,
      disabled: !authServer.isAllRoles(['pm', 'b2b']),
    },
    'timetable.time.start_time': {
      validate: validateRequired,
      disabled: !authServer.isAllRoles(['pm', 'b2b']),
    },
    'timetable.time.end_time': {
      validate: validateRequired,
      disabled: !authServer.isAllRoles(['pm', 'b2b']),
    },
    'timetable.time.weekend_start_time': {
      validate: validateRequired,
      disabled: !authServer.isAllRoles(['pm', 'b2b']),
    },
    'timetable.time.weekend_end_time': {
      validate: validateRequired,
      disabled: !authServer.isAllRoles(['pm', 'b2b']),
    },
    'staff.pm': {
      validate: validateRequired,
      disabled: !authServer.isAllRoles(['pm', 'b2b']),
    },
    'prework.title': {
      validate: validateRequired,
      disabled: !authServer.isAllRoles(['pm', 'b2b']),
    },
    'prework.openedx': {
      validate: validateRequired,
      disabled: !authServer.isAllRoles(['pm', 'b2b']),
    },
    'course.title': {
      validate: validateRequired,
      disabled: !authServer.isAllRoles(['pm', 'b2b']),
    },
    'course.openedx': {
      validate: validateRequired,
      disabled: !authServer.isAllRoles(['pm', 'b2b']),
    },
    'career_services.title': {
      validate: R.hasPath(['record', 'career_services'], props)
        ? validateRequired
        : [],
      disabled: false,
    },
    'career_services.openedx': {
      validate: R.hasPath(['record', 'career_services'], props)
        ? validateRequired
        : [],
      disabled: false,
    },
    'external_ids.cybint_access_code': {
      validate: [],
      disabled: !authServer.isAllRoles(['pm', 'b2b']),
    },
    feature_flags: {
      validate: [],
      disabled: !authServer.isAllRoles(['admin']),
    },
    'staff.teachers': {
      validate: [],
      disabled: !authServer.isAllRoles(['pm', 'b2b']),
    },
  };

  return (
    <FormWithRedirect
      {...props}
      mutators={mutators}
      validate={validateCohortEdit}
      render={(formProps) => {
        return (
          <form>
            <Box display="block" p="1.5em">
              <Typography variant="h5" gutterBottom>
                Cohort
              </Typography>
              <Box display={{ sm: 'block', md: 'flex' }}>
                <Box flex={1} mr={{ md: '2em' }}>
                  <SelectInput
                    label="Track"
                    source="track"
                    choices={tracksB2b}
                    variant="standard"
                    fullWidth
                    {...fieldsConfig.track}
                  />
                </Box>
                <Box flex={1} mr={{ md: '2em' }}>
                  <SelectInput
                    label="Format"
                    source="format"
                    choices={formats}
                    variant="standard"
                    fullWidth
                    {...fieldsConfig.format}
                  />
                </Box>
                <Box flex={1} mr={{ md: '2em' }}>
                  <SelectInput
                    label="Timezone"
                    source="timezone"
                    choices={cohortTimezones}
                    variant="standard"
                    fullWidth
                    {...fieldsConfig.timezone}
                  />
                </Box>
                <Box flex={1}>
                  <SelectInput
                    label="Language"
                    source="language"
                    choices={languages}
                    variant="standard"
                    fullWidth
                    {...fieldsConfig.language}
                  />
                </Box>
              </Box>
              <Box display={{ sm: 'block', md: 'flex' }}>
                <Box flexBasis="calc((100% - 6em)/4)" mr={{ md: '2em' }}>
                  <TextInput
                    label="Company"
                    source="company"
                    validate={validateRequired}
                    variant="standard"
                    fullWidth
                  />
                </Box>
                <Box flexBasis="calc((100% - 6em)/4)" mr={{ md: '2em' }}>
                  <SelectInput
                    label="Project Type"
                    source="project_type"
                    choices={b2bProjectTypes}
                    validate={validateRequired}
                    variant="standard"
                    fullWidth
                  />
                </Box>
                <Box flexBasis="calc((100% - 6em)/4)">
                  <NumberInput
                    label="Hours per week"
                    source="week_hours"
                    validate={validateRequiredNumbers}
                    defaultValue={0}
                    variant="standard"
                    fullWidth
                  />
                </Box>
              </Box>
              <Box mb="3em" />
              <Typography variant="h5" gutterBottom>
                Dates
              </Typography>
              <Box display={{ sm: 'block', md: 'flex' }}>
                <Box flex={1} mr={{ md: '2em' }}>
                  <DateInput
                    label="Start Date"
                    source="start_date"
                    options={{
                      format: 'Do MMMM YYYY',
                      openTo: 'year',
                      views: ['year', 'month', 'date'],
                    }}
                    variant="standard"
                    fullWidth
                    {...fieldsConfig['start_date']}
                  />
                </Box>
                <Box flex={1}>
                  <DateInput
                    label="End Date"
                    source="end_date"
                    options={{
                      format: 'Do MMMM YYYY',
                      openTo: 'year',
                      views: ['year', 'month', 'date'],
                    }}
                    variant="standard"
                    fullWidth
                    {...fieldsConfig['end_date']}
                  />
                </Box>
              </Box>
              <FormDataConsumer>
                {({ formData }) =>
                  R.equals('pt', formData.format) && (
                    <>
                      <Box display={{ sm: 'block', md: 'flex' }} mt="1em">
                        <Box flex={1}>
                          <SelectInput
                            label="Part Time Days"
                            source="timetable.days"
                            choices={partTimeDays}
                            variant="standard"
                            fullWidth
                            {...fieldsConfig['timetable.days']}
                          />
                        </Box>
                        <Box flex={1} ml={{ md: '2em' }}>
                          <SelectInput
                            source="timetable.time.0.start_time"
                            label="Weekday Start Time"
                            choices={partTimeHours}
                            optionText="id"
                            variant="standard"
                            fullWidth
                            {...fieldsConfig['timetable.time.start_time']}
                          />
                        </Box>
                        <Box flex={1} ml={{ md: '2em' }}>
                          <SelectInput
                            source="timetable.time.0.end_time"
                            label="Weekday End Time"
                            choices={partTimeHours}
                            optionText="id"
                            variant="standard"
                            fullWidth
                            {...fieldsConfig['timetable.time.end_time']}
                          />
                        </Box>
                      </Box>
                      <Box
                        display={{ sm: 'block', md: 'flex' }}
                        justifyContent="flex-end"
                      >
                        <Box flex={1} />
                        <Box ml={{ md: '2em' }} flex={1}>
                          <SelectInput
                            source="timetable.time.1.start_time"
                            label="Weekend Start Time"
                            choices={partTimeHours}
                            optionText="id"
                            variant="standard"
                            fullWidth
                            {...fieldsConfig[
                              'timetable.time.weekend_start_time'
                            ]}
                          />
                        </Box>
                        <Box ml={{ md: '2em' }} flex={1}>
                          <SelectInput
                            source="timetable.time.1.end_time"
                            label="Weekend End Time"
                            choices={partTimeHours}
                            optionText="id"
                            variant="standard"
                            fullWidth
                            {...fieldsConfig['timetable.time.weekend_end_time']}
                          />
                        </Box>
                      </Box>
                    </>
                  )
                }
              </FormDataConsumer>
              <Box mb="3em" />
              <Typography variant="h5" gutterBottom>
                Staff
              </Typography>
              <Box display={{ sm: 'block', md: 'flex' }}>
                <Box flex={1} mr={{ md: '2em' }}>
                  <SelectInput
                    label="PM"
                    source="staff.pm"
                    format={(pm) =>
                      pm ? `${pm.email}#${pm.first_name}#${pm.last_name}` : ''
                    }
                    parse={R.pipe(
                      R.defaultTo(''),
                      R.split('#'),
                      R.zipObj(['email', 'first_name', 'last_name'])
                    )}
                    choices={R.map((pm) => {
                      pm.info = `${pm.email}#${pm.first_name}#${pm.last_name}`;
                      return pm;
                    }, R.defaultTo([], pms.data))}
                    variant="standard"
                    optionText={(pm) => `${pm.first_name} ${pm.last_name}`}
                    optionValue="info"
                    fullWidth
                    {...fieldsConfig['staff.pm']}
                  />
                </Box>
                <Box flex={1}>
                  <NumberInput
                    source="staff.ta_amount"
                    label="Number of TAs"
                    variant="standard"
                    min={0}
                    step={1}
                    fullWidth
                    {...fieldsConfig['staff.ta_amount']}
                  />
                </Box>
              </Box>
              <Box display={{ sm: 'block', md: 'flex' }}>
                <Box flex={1}>
                  <SelectArrayInput
                    label="Lead Teachers"
                    source="staff.teachers"
                    choices={R.map((teacher) => {
                      teacher.info = `${teacher.email}#${teacher.first_name}#${teacher.last_name}`;
                      return teacher;
                    }, R.defaultTo([], teachers.data))}
                    format={R.pipe(
                      R.defaultTo([]),
                      R.map((teacher) =>
                        teacher
                          ? `${teacher.email}#${teacher.first_name}#${teacher.last_name}`
                          : ''
                      )
                    )}
                    parse={R.pipe(
                      R.defaultTo([]),
                      R.map(
                        R.pipe(
                          R.defaultTo(''),
                          R.split('#'),
                          R.zipObj(['email', 'first_name', 'last_name'])
                        )
                      )
                    )}
                    variant="standard"
                    optionText={(teacher) =>
                      teacher ? `${teacher.first_name} ${teacher.last_name}` : ''
                    }
                    optionValue="info"
                    fullWidth
                    {...fieldsConfig['staff.teachers']}
                  />
                </Box>
              </Box>
              <Box>
                <Typography variant="h5" gutterBottom>
                  Courses
                </Typography>
                <Box display={{ sm: 'block', md: 'flex' }} mt="10px">
                  <Box flex={1} flexDirection="row" mr={{ md: '2em' }}>
                    {R.hasPath(['record', 'prework'], props) && (
                      <Box>
                        <Box mb="10px">
                          <Typography variant="h6" gutterBottom>
                            Prework
                          </Typography>
                        </Box>
                        <Box>
                          <TextInput
                            source="prework.title"
                            label="Student Portal Title"
                            variant="standard"
                            fullWidth
                            {...fieldsConfig['prework.title']}
                          />
                        </Box>
                        <Box>
                          <TextInput
                            source="prework.openedx"
                            label="OpenEdx Id"
                            variant="standard"
                            fullWidth
                            {...fieldsConfig['prework.openedx']}
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>
                  <Box flex={1} flexDirection="row" mr={{ md: '2em' }}>
                    <Box mb="10px">
                      <Typography variant="h6" gutterBottom>
                        Main Course
                      </Typography>
                    </Box>
                    <Box>
                      <TextInput
                        source="course.title"
                        label="Student Portal Title"
                        variant="standard"
                        fullWidth
                        {...fieldsConfig['course.title']}
                      />
                    </Box>
                    <Box>
                      <TextInput
                        source="course.openedx"
                        label="OpenEdx Id"
                        variant="standard"
                        fullWidth
                        {...fieldsConfig['course.openedx']}
                      />
                    </Box>
                  </Box>
                  <Box flex={1} flexDirection="row" mr={{ md: '2em' }}>
                    <Box>
                      <Box mb="10px">
                        <Typography variant="h6" gutterBottom>
                          Career Services
                        </Typography>
                      </Box>
                      <Box>
                        <TextInput
                          source="career_services.title"
                          label="Student Portal Title"
                          variant="standard"
                          fullWidth
                          {...fieldsConfig['career_services.title']}
                        />
                      </Box>
                      <Box>
                        <TextInput
                          source="career_services.openedx"
                          label="OpenEdx Id"
                          variant="standard"
                          fullWidth
                          {...fieldsConfig['career_services.openedx']}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Typography variant="h5" gutterBottom>
                  Cohort specifics
                </Typography>
                <Box display={{ sm: 'block', md: 'flex' }}>
                  {authServer.isRoles(['admin']) && !emailsLoading && (
                    <Box flexBasis="32%" mr={{ md: '2em' }}>
                      <SelectInput
                        label="Course Welcome email"
                        source="course.email_templates.welcome_email_template_id"
                        choices={R.pipe(
                          R.map((template) =>
                            R.assoc(
                              'name',
                              `${template.name}${
                                template.language
                                  ? `- ${template.language.toUpperCase()}`
                                  : ''
                              }`,
                              template
                            )
                          ),
                          R.sortBy(R.prop('name'))
                        )(emails)}
                        variant="standard"
                        fullWidth
                      />
                    </Box>
                  )}
                  <Box flexBasis="68%">
                    <TextInput
                      label="Link to FAQs"
                      source="faq_url"
                      validate={validateUrl}
                      variant="standard"
                      fullWidth
                    />
                  </Box>
                </Box>
                <Box display={{ sm: 'block', md: 'flex' }}>
                  <Box flexBasis="calc((100% - 4em)/3)">
                    <TextInput
                      label="Accredible design ID"
                      source="external_ids.accredible_design_id"
                      variant="standard"
                      fullWidth
                    />
                  </Box>
                </Box>
                <Box display={{ sm: 'block', md: 'flex' }}>
                  <Box flexBasis="calc((100% - 4em)/3)">
                    <TextInput
                      label="Text for client banner"
                      source="banner.copy"
                      variant="standard"
                      fullWidth
                    />
                  </Box>
                </Box>
                <Box>
                  <ImageInput
                    source="images"
                    classes={classes}
                    label="Client logos"
                    accept="image/*"
                    multiple
                    labelMultiple="Drop files or click to select them (Images). The maximum size is 3MB"
                    maxSize={3000000}
                  >
                    <ImageField source="src" title="title" />
                  </ImageInput>
                </Box>
                <Box width="120px">
                  <BooleanInput
                    label="AI Chatbot active"
                    source="feature_flags.ai_chatbot_enabled"
                    defaultValue={false}
                    variant="standard"
                    fullWidth
                    {...fieldsConfig.feature_flags}
                  />
                  <BooleanInput
                    label="Labs active"
                    source="feature_flags.labs_enabled"
                    defaultValue={false}
                    variant="standard"
                    fullWidth
                    {...fieldsConfig.feature_flags}
                  />
                </Box>
              </Box>
              <FormDataConsumer>
                {({ formData }) =>
                  R.propEq('track', 'cy', formData) && (
                    <Box>
                      <Typography variant="h5" gutterBottom>
                        External IDs
                      </Typography>
                      <Box>
                        <TextInput
                          source="external_ids.cybint_access_code"
                          label="Cybint Access Code"
                          variant="standard"
                          fullWidth
                        />
                      </Box>
                    </Box>
                  )
                }
              </FormDataConsumer>
            </Box>
            <Toolbar>
              {authServer.isAllRoles(['pm', 'b2b']) && (
                <Box display="flex" justifyContent="space-between" width="100%">
                  <CohortB2bSaveButton
                    record={formProps.record}
                    basePath={formProps.basePath}
                    undoable={true}
                    invalid={formProps.invalid}
                    saving={formProps.saving}
                    resource="cohorts"
                    submitOnEnter={false}
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect
                    }
                  />
                  {authServer.isAllRoles(['pm', 'b2b']) && (
                    <FormDataConsumer>
                      {({ formData }) => {
                        const calculateCohortDates = () => {
                          const mutator = R.path(
                            ['form', 'mutators', 'cohortDatesMutator'],
                            formProps
                          );
                          mutator();
                        };
                        return (
                          <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<DateRange />}
                            onClick={calculateCohortDates}
                            disabled={
                              !R.allPass([
                                R.has('format'),
                                R.has('start_date'),
                                R.complement(R.propEq)('format', 'other'),
                              ])(formData)
                            }
                          >
                            Calculate Cohort Dates
                          </Button>
                        );
                      }}
                    </FormDataConsumer>
                  )}
                </Box>
              )}
            </Toolbar>
          </form>
        );
      }}
    />
  );
};

const CohortB2bEdit = ({ classes, ...props }) => (
  <Edit title={<CohortTitle />} actions={null} undoable={false} {...props}>
    <CohortB2bEditForm redirect="show" />
  </Edit>
);

export default CohortB2bEdit;
