import React from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import {
  Button,
  Datagrid,
  EditButton,
  Labeled,
  ReferenceManyField,
  ShowController,
  ShowView,
  Tab,
  TextField,
  TopToolbar,
  useDataProvider,
  useNotify,
  useRedirect,
} from 'react-admin';
import { Box, makeStyles } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { alpha } from '@material-ui/core/styles/colorManipulator';

import { authServer } from 'auth/keycloak';
import { ChangesCodeBlock, DateField, NavigableTabs } from 'resources/common';
import { SalesforceField } from 'resources/common/cohorts/list/fields';
import { BookmarkField } from '../../common/cohorts/list/fields';
import {
  AttendanceTab,
  CareerhackProgressTab,
  InformationTab,
  ActionsTab,
  OneOnOnesTab,
  ProgressTab,
  SkillsTab,
  StudentsTab,
  SurveysTab,
} from './tabs';

const CohortTitle = ({ record }) =>
  `${record.campus.toUpperCase()} ${record.track.toUpperCase()} ${record.format.toUpperCase()} ${
    record.start_date.split('T')[0]
  } ${record.language.toUpperCase()}`;

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
}));

const CohortShowActions = ({ basePath, data, deleteCohort }) => {
  const classes = useStyles();
  return (
    <TopToolbar>
      <BookmarkField record={data} size="small" labeled />
      {authServer.isRoles([
        'gm',
        'finance',
        'pm',
        'outcomes',
        'campus_lead',
        'education',
      ]) && <EditButton basePath={basePath} record={data} />}
      {data && authServer.isRoles(['finance', 'gm']) && (
        <Button
          className={classes.deleteButton}
          label="Delete"
          onClick={deleteCohort(data)}
        >
          <Delete />
        </Button>
      )}
    </TopToolbar>
  );
};
const CohortShow = (props) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const deleteCohort = (cohort) => async () => {
    const result = await dataProvider.getManyReference('students', {
      target: 'cohorts',
      id: cohort.id,
      pagination: { perPage: 1 },
      filter: { Object },
    });
    if (result.total === 0) {
      await dataProvider.delete('cohorts', {
        id: cohort.id,
      });
      redirect('/cohorts');
      notify('Cohort deleted');
    } else {
      notify('Cannot delete a cohort with onbaorded students');
    }
  };

  const isSidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen);

  return (
    // Forces the max with to allow tabs to be scrollable and avoid horizontal scroll
    <Box css={{ maxWidth: `calc(100vw - ${isSidebarOpen ? 229 : 84}px)` }}>
      <ShowController {...props}>
        {(controllerProps) => {
          const record = R.propOr({}, 'record', controllerProps);
          return (
            <ShowView
              {...props}
              {...R.omit(['translate'], controllerProps)}
              title={<CohortTitle />}
              actions={<CohortShowActions deleteCohort={deleteCohort} />}
            >
              <NavigableTabs
                matchUrl={R.pipe(R.split('/'), R.take(5), R.join('/'))}
                variant="scrollable"
              >
                <Tab label="Cohort Information" path="info">
                  <InformationTab {...props} />
                </Tab>
                <Tab label="Actions" path="actions">
                  <ActionsTab {...props} />
                </Tab>
                <Tab label="Students" path="students">
                  <StudentsTab rowClick="show" {...props} />
                </Tab>
                {authServer.isRoles([
                  'pm',
                  'gm',
                  'education',
                  'teacher',
                  'ta',
                  'campus_lead',
                  'outcomes',
                ]) ? (
                  <Tab label="Surveys" path="surveys">
                    <SurveysTab {...props} />
                  </Tab>
                ) : null}
                {authServer.isRoles([
                  'pm',
                  'ta',
                  'teacher',
                  'education',
                  'campus_lead',
                ]) && record.preparation ? (
                  <Tab label="Prep work" path="preparation_progress">
                    <ProgressTab course="preparation" {...props} />
                  </Tab>
                ) : null}
                {authServer.isRoles([
                  'pm',
                  'ta',
                  'teacher',
                  'education',
                  'campus_lead',
                ]) ? (
                  <Tab label="Prework" path="prework_progress">
                    <ProgressTab course="prework" {...props} />
                  </Tab>
                ) : null}
                {authServer.isRoles([
                  'campus_lead',
                  'pm',
                  'gm',
                  'outcomes',
                  'ta',
                  'teacher',
                ]) && record.track === 'wd' ? (
                  <Tab label="Skills" path="skills">
                    <SkillsTab {...props} />
                  </Tab>
                ) : null}
                {authServer.isRoles([
                  'pm',
                  'ta',
                  'teacher',
                  'education',
                  'campus_lead',
                  'outcomes',
                ]) ? (
                  <Tab label="Course Progress" path="course_progress">
                    <ProgressTab course="course" {...props} />
                  </Tab>
                ) : null}
                {authServer.isRoles(['outcomes']) ? (
                  <Tab label="Progress" path="careerhack_progress">
                    <CareerhackProgressTab {...props} />
                  </Tab>
                ) : null}
                {authServer.isRoles(['pm', 'teacher', 'ta', 'campus_lead']) ? (
                  <Tab label="Attendance" path="attendance">
                    <AttendanceTab {...props} />
                  </Tab>
                ) : null}
                {authServer.isNotRoles(['education', 'finance']) ? (
                  <Tab label="One On Ones" path="one_on_ones">
                    <OneOnOnesTab {...props} />
                  </Tab>
                ) : null}
                {authServer.isRoles(['product']) ? (
                  <Tab label="History" path="history">
                    <ReferenceManyField
                      addLabel={false}
                      source="id"
                      reference="changes"
                      target="id"
                    >
                      <Datagrid rowClick="expand" expand={<ChangesCodeBlock />}>
                        <TextField label="Event" source="event" />
                        <DateField label="Date" source="date" showTime />
                        <TextField label="User" source="user" />
                        <TextField label="App" source="client" />
                      </Datagrid>
                    </ReferenceManyField>
                  </Tab>
                ) : null}
                {authServer.isRoles(['product', 'finance']) ? (
                  <Tab label="Meta" path="meta">
                    <Box p="16px" display="block">
                      <Box>
                        <Labeled label="Internal Id">
                          <TextField source="id" record={record} />
                        </Labeled>
                      </Box>
                      <Box>
                        <Labeled label="Salesforce Id">
                          <SalesforceField
                            source="external_ids.salesforce"
                            record={record}
                          />
                        </Labeled>
                      </Box>
                    </Box>
                  </Tab>
                ) : null}
              </NavigableTabs>
            </ShowView>
          );
        }}
      </ShowController>
    </Box>
  );
};

export default CohortShow;
